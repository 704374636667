import React from "react";
import IncentivoTiendasContainer from "./IncentivoTiendasContainer";

const IncentivoTiendas = (props) => {
  return (
      <IncentivoTiendasContainer location={props.location} history={props.history} match={props.match}/>
  );
};

export default IncentivoTiendas;
