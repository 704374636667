import React, { useEffect, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import { withTheme } from 'styled-components';
import { Layout } from './_layout';
import styled from 'styled-components';
//import Authorization from './hocs/RoleAuthHoc';
//import { LINKS } from './constants';
//import { useBreakpoints } from './components/shared';
import { breakpoints } from './_helpers/breakpoints';
import Home from './_pages/Home';
import Campus from './_pages/Training';
import Course from './_pages/Training/Course';
import Category from './_pages/Training/Category';
import Module from './_pages/Training/Module';
import IncentivoTiendas from './_pages/IncentivoTiendas';

import CategoryTrainingsContainer from './_pages/Campus/CategoryTrainingsContainer';
import TrainingContainer from './_pages/Campus/TrainingContainer';
import LatamBox from './_pages/LatamBox';

import VideoLanding from './_pages/VideoLanding';
import SecurityDay from './_pages/SecurityDay';

//import Encuesta from './_pages/Encuesta';
//import Fixture from './_pages/Fixture';
import ChallengeBox from './_pages/ChallengeBox';

import Trivia from './_pages/Trivia';
import Baristas from './_pages/Baristas';

import TriviaJugar from './_pages/Trivia/TriviaJugar';
import TriviaRanking from './_pages/Trivia/TriviaRanking';
import Perfil from './_pages/Perfil';
import Catalogo from './_pages/Catalogo';
import SubcategoriesContainer from './_pages/Catalogo/SubcategoriesContainer';


import { AuthContext, ContentProvider } from './_context';
import { SideLeft, SideRight, DashboardStats, MenuMobile } from './_components';



const WrapperStyled = styled.div`

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    //margin-left: 120px;
    /*
    padding-top: 3vw;
    padding-bottom: 3vw;
    padding-left: 3vw;
    padding-right: 3vw;
    */
    //border-bottom: 3px solid #FFFFFF;
    //cursor: pointer;
    .sideright{
      width: calc(100% - 40px);
      margin-left: 0;
    }

    @media ${breakpoints.xs} {//576px

    }
    @media ${breakpoints.sm} {//768px
      align-items: center;
      .sideright{
        //width: 100vw;
        //margin-left: 120px;
      }
    }
    @media ${breakpoints.md} {//992px
      align-items: flex-start;
      .sideright{
        display: flex;
        align-items: flex-start;
        width: calc(100% - 162px);
        margin-left: 130px;
      }
    }
    @media ${breakpoints.lg} {//1200px
      align-items: flex-start;
      .sideright{
        width: calc(100% - 162px);
        margin-left: 130px;
      }
    }
    @media ${breakpoints.xl} {//1366px

    }
    @media ${breakpoints.xxl} {//1440px

    }
    @media ${breakpoints.xxxl} {//1680px

    }
    @media ${breakpoints.xxxxl} {//1920px
    }


`
const Container = styled.div`
  display: flex;
  width: 100%;
`

const App = ({ theme }) => {

  const authContext = useContext(AuthContext);
  const history = useHistory();

  if(authContext.isAuth == false){
    return null;
  }

  return (
    <ContentProvider>

      <Layout>
        <WrapperStyled>
          <SideRight className="sideright">
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/campus" exact component={Campus} />
                <Route path="/campus/course/:id_course" exact component={Course} />
                <Route path="/campus/course/:id_course/module/:id_module" exact component={Module} />
                <Route path="/campus/module/:id_module" exact component={Module} />
                <Route path="/campus/category/:id_category" exact component={Category} />
                <Route path="/perfil" exact component={Perfil} />
                <Route path="/catalogo" exact component={Catalogo} />
                <Route path="/catalogo/:id_category" exact component={SubcategoriesContainer} />
                <Route path="/trivia" exact component={Trivia} />
                <Route path="/incentivo-tiendas" exact component={IncentivoTiendas} />
            </Switch>
            <Route path="/semana-de-la-seguridad" exact component={SecurityDay} />
            {/*<Route path="/encuesta" exact component={Encuesta} />*/}
            {/*<Route path="/qatar2022" exact component={Fixture} />

            {/*
            <Switch>

              <Route path="/campus" exact component={Campus} />
              <Route path="/campus/:id" exact component={CategoryTrainingsContainer} />
              <Route path="/campus/:id/:id" exact component={TrainingContainer} />
              <Route path="/novedades" exact component={Home} />
              <Route path="/productos" exact component={Catalogo} />
              <Route path="/shellbox" exact component={LatamBox} />

              <Route path="/challengebox" exact component={ChallengeBox} />

              <Route path="/trivia" exact component={Trivia} />
              <Route path="/trivia/jugar" exact component={TriviaJugar} />
              <Route path="/trivia/ranking" exact component={TriviaRanking} />

              <Route path="/video-landing" exact component={VideoLanding} />


              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
            */}
          </SideRight>
          <Switch>
              <Route path="/baristas" exact component={Baristas} />
          </Switch>
        </WrapperStyled>
      </Layout>

      <MenuMobile />
    </ContentProvider>
  );
};

export default withTheme(App);
