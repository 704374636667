import React, { useState, useContext, useEffect, Fragment } from 'react';
import { path } from 'ramda';
import styled from 'styled-components';
import { ButtonYellow } from '../../../_elements';
import { Logo,
         Spinner } from '../../../_helpers/constants';
import { AuthContext } from '../../../_context/AuthContext';
import { ContentContext } from '../../../_context';
import storage from 'local-storage-fallback';
import { breakpoints } from '../../../_helpers/breakpoints';
import Content from '../../../_components/Training/Content';
import Like from '../../../_components/Training/Like';
import CircularProgress from '@material-ui/core/CircularProgress';
import Breadcrumb from '../../../_components/Training/Breadcrumb';
import { AiOutlineCheckCircle } from "react-icons/ai";
import Evaluation from '~/_components/Evaluation';
import Modal from '@mui/material/Modal';

import { haveModulesPendings,
         STATUS_COURSE_BLOCKED,
         STATUS_COURSE_UNLOCKED,
         STATUS_COURSE_VIEWED,
         STATUS_COURSE_COMPLETED,
         STATUS_COURSE_REPROVED,
         STATUS_COURSE_PENDING_CONNECTION,
         STATUS_COURSE_IN_PROGRESS,
         getCourseCategoryId } from '~/_helpers/commons';

const WrapperStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  >.loader{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-top: 20px;
    svg{
      color: #FFC600;
    }
  }
  .course{
    width: 100%;
    .course_header{
      display: flex;
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
      margin-top: 10px;
      align-items: center;
      >.image{
        width: 220px;
        height: 220px;
        margin-right: 0;
        margin-bottom: 20px;
        img{
          width: 100%;
        }
      }
      .inner_course_header{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 0;
        >.descripcion{
          margin-top: 20px;
        }
        >.title{
          font-size: 25px;
          line-height: 29px;
          font-weight: 600;
          margin-bottom: 15px;
        }
        .evaluation_like{
          display: flex;
          flex-direction: row;
          align-items: center;
          .box_like{
            //margin-left: 10px;
            margin-right: 10px;
          }
          .last_evaluation_result{
            color: #556377;
            .value{
              font-weight: 600;
            }
          }
          .bt_evaluation{
            margin-right: 10px;
          }
          >.loader{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            height: 45px;
            svg{
              display: flex;
              color: #FFD200!important;
            }
          }
        }
      }
      p{
        padding: 0;
        margin: 0;
      }
    }
  }

  .container_features{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 30px;
  }
  .container_categories{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .course_modules{
    width: 100%;
    margin-top: 20px;
  }
  @media ${breakpoints.xs} {
    .course{
      width: 100%;
      .course_header{
        display: flex;
        flex-direction: row;
        padding-left: 0;
        padding-right: 0;
        box-sizing: border-box;
        margin-top: 10px;
        align-items: flex-start;
        >.image{
          width: 140px;
          height: 140px;
          margin-right: 20px;
          img{
            width: 100%;
          }
        }
        .inner_course_header{
          display: flex;
          flex-direction: column;
          width: calc(100% - 160px);
          >.descripcion{
            margin-top: 20px;
          }
          >.title{
            font-size: 25px;
            line-height: 29px;
            font-weight: 600;
            margin-bottom: 15px;
          }
          .evaluation_like{
            display: flex;
            flex-direction: row;
            align-items: center;
            .box_like{
              //margin-left: 10px;
              margin-right: 10px;
            }
            .last_evaluation_result{
              color: #556377;
              .value{
                font-weight: 600;
              }
            }
            .bt_evaluation{
              margin-right: 10px;
            }
            >.loader{
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              width: 100%;
              height: 45px;
              svg{
                display: flex;
                color: #FFD200!important;
              }
            }
          }
        }
        p{
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  @media ${breakpoints.sm} {
    .course{
      width: 100%;
      .course_header{
        display: flex;
        flex-direction: row;
        padding-left: 0;
        padding-right: 0;
        box-sizing: border-box;
        margin-top: 10px;

        >.image{
          width: 140px;
          height: 140px;
          margin-right: 20px;
          img{
            width: 100%;
          }
        }
        .inner_course_header{
          display: flex;
          flex-direction: column;
          width: calc(100% - 160px);
          margin-left: ${props => props.haveCover ? '0' : '25px'};
          >.descripcion{
            margin-top: 20px;
          }
          >.title{
            font-size: 25px;
            line-height: 29px;
            font-weight: 600;
            margin-bottom: 15px;
          }
          .evaluation_like{
            display: flex;
            flex-direction: row;
            align-items: center;
            .box_like{
              //margin-left: 10px;
              margin-right: 10px;
            }
            .last_evaluation_result{
              color: #556377;
              .value{
                font-weight: 600;
              }
            }
            .bt_evaluation{
              margin-right: 10px;
            }
            >.loader{
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              width: 100%;
              height: 45px;
              svg{
                display: flex;
                color: #FFD200!important;
              }
            }
          }
        }
        p{
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  @media ${breakpoints.md} {
    .course{
      padding-left: 25px;
    }
  }
  @media ${breakpoints.lg} {

  }
`

const ButtonStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  height: 45px;
  background-color: #FFC600;
  border: 1px solid #FFC600;
  box-sizing: border-box;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
  >.icon{
    display: flex;
    font-size: 21px;
    margin-left: 5px;
  }
  p{
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    color: #292929;
    padding: 0;
    margin: 0;
  }
  :hover{
    border: 1px solid #292929;
    background-color: #FFFFFF;
  }
  &.approved{
    cursor: default;
    background-color: #FFFFFF;
    border: 1px solid #292929;
  }
  &.disabled{
    cursor: default;
    background-color: #FFC600;
    border: 1px solid #FFC600;
    opacity: 0.5;
    :hover{
      background-color: #FFC600;
      border: 1px solid #FFC600;
    }
  }
`

const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
`

const CourseContainer = (props) => {

  const { history, location, match } = props;

  const { login,
          isAuthLoading,
          loginError } = useContext(AuthContext);


  const { getCampus,
          campus,
          setCampus,
          set_add_like,
          get_course_by_id,
          take_user_evaluation,
          crumbs,
          setCrumbs,
          //course,
          //setCourse,
          lastCourse,
          setLastCourse } = useContext(ContentContext);

  const [ user, setUser ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ loading, setLoading ] = useState(false);

  const [showLogin, setShowLogin] = useState(true);
  const [showRetrieve, setShowRetrieve] = useState(false);
  const [ evaluationData, setEvaluationData ] = useState(null);
  const [ errorFields, setErrorFields ] = useState({user: false, password: false});
  const [ evaluation, setEvaluation ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isLoadingLike, setIsLoadingLike ] = useState(false);
  const [ evaluationOpen, setEvaluationOpen ] = useState(false);
  const [ course, setCourse ] = useState(null);
  const [ evaluationStatus, setEvaluationStatus ] = useState("");

  useEffect(() => {

    if(lastCourse !== match.params.id_course){
      setLastCourse(match.params.id_course);
    }

    return () => {}
  }, [])

  useEffect(() => {

    //setIsLoading(true);
    //get_course_by_id(match.params.id_course);

    return () => {}
  }, [])

  /*
  useEffect(() => {

    if(course){
      const response = take_user_evaluation("", course._id);
      response.then((res) => {
        setEvaluation(path(['data', 'single'], res));
      })

      setIsLoading(false);
      setCrumbs([{_id: null, label: "Campus", type: "section", route: `/campus`},
                 {_id: course._id, label: course.title, type: "moducoursele", route: `/campus/course/${match.params.id_course}`}])

    }

    return () => {}
  }, [course])
  */

  useEffect(() => {
    console.log('🚀', campus);
    if(!campus){
      getCampus();
    }else{
      if(match.params.id_course){
        let courses;
        let course_id;
        let category_id;

        const course_splitted = match.params.id_course.split(":");
        if(course_splitted.length === 1){
          course_id = match.params.id_course;
          category_id = getCourseCategoryId(match.params.id_course, campus.categories);
          history.push(`${category_id}:${course_id}`);
          //return null;
        }else{
          category_id = course_splitted[0];
          course_id = course_splitted[1];
        }

        courses = campus.categories.filter(category => category._id === category_id);

        if(courses.length){
          const course_filtered = courses[0].repository.filter(course => course._id === course_id);
          if(course_filtered.length){
            const evaluation = course_filtered[0].evaluations;
            if(evaluation.length){
              setEvaluation(evaluation[0]);
            }else{
              setEvaluation({});
            }
            if(course_filtered[0].status === STATUS_COURSE_COMPLETED){
              setEvaluationStatus("complete");
            }else{
              
              if(course_filtered[0].progressive_mode){
                if(course_filtered[0].moduls.length){
                  const modules_pendings = course_filtered[0].moduls.filter(module => (module.status !== STATUS_COURSE_VIEWED && module.status !== STATUS_COURSE_COMPLETED));
                  if(modules_pendings.length){
                    setEvaluationStatus("blocked");
                  }else{
                    setEvaluationStatus("active");
                  }
                }else{
                  setEvaluationStatus("blocked");
                }

              }
            }
            setCourse(course_filtered[0]);
            setCrumbs([{_id: null, label: "Campus", type: "section", route: `/campus`},
                      {_id: course_splitted[1], label: course_filtered[0].title, type: "moducoursele", route: `/campus/course/${match.params.id_course}`}])
            setIsLoading(false);
          }else{
            // No hay curso
            setIsLoading(false);
          }
        }
      }
      
    }

    return () => {}
  }, [campus])

  const handleOpenEvaluation = (id) => {
    console.log("Evaluatio", evaluation)
    setEvaluationOpen(true);
    /*
    setEvaluationData({type: "course", id: id});
    
    */
  }

  const handleSetLike = (payload) => {

    setIsLoadingLike(true);
    const response = set_add_like(payload);
    response.then((res) => {

      setIsLoadingLike(false);
      const course_edited = {...course};
      if(course_edited.status_liked){
        course_edited.likes = course_edited.likes - 1;
        course_edited.status_liked = false;
      }else{
        course_edited.likes = course_edited.likes + 1;
        course_edited.status_liked = true;
      }
      setCourse(course_edited);

    })
  }

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setEvaluationOpen(false)
    }
  }

  const handleOpen = () => {
    setEvaluationOpen(true);
  }

  const handleRefreshData = (payload) => {

    let module_arr = JSON.parse(payload.moduls);
    let module_parsed = JSON.parse(payload.moduls);
    let module_parsed_filter = module_parsed.filter(mod => mod._id === match.params.id_module);
    if(module_parsed_filter.length){
      module_parsed = module_parsed_filter;
    }
    const course_splitted = match.params.id_course.split(":");

    const campus_update = campus.categories.map(category => {
      if(course_splitted[0] === category._id){
        const repository_update = category.repository.map(repo => {
          if(repo._id === course_splitted[1]){
            let active_next_index = null;
            const module_update = repo.moduls.map(module => {
              for (let i = 0; i < module_arr.length; i++) {
                if(module_arr[i]._id === module._id){
                  module.status = module_arr[i].status;
                }
              }
              return module;
            })
            const status = payload.status;
            repo.status = status;
          }
          return repo;
        })
      }
      return category;
    })

    const campus_update_arr = {...campus};
    campus_update_arr.categories = campus_update;
    setCampus(campus_update_arr);

  }

  if(!campus){
    return null;
  }
  
  return (
    <WrapperStyled
      haveCover={ course?.cover_image ? true : false }
    >

      <ModalStyled
        open={evaluationOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        //onBackdropClick={(event) => {event.stopPropagation(); return false;}}
        disableEscapeKeyDown
      >
        <Fragment>
          {evaluation && //evaluationData
              <Evaluation
                _id={match.params.id_course}
                scope={"course"}
                title={course.title}
                parentData={course}
                evaluation={evaluation}
                setEvaluation={setEvaluation}
                evaluationData={evaluationData/*evaluationData*/}
                setEvaluationData={setEvaluationData/*setEvaluationData*/}
                handleClose={handleClose}
              />
          }
        </Fragment>
      </ModalStyled>

      <Breadcrumb
        crumbs={crumbs}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        {...props}
      />

      {course == null ?
        <div className='loader'>
          <CircularProgress />
        </div>
        :
        <div className='course'>

          <div className='course_header'>

            <div className='image'>
              <img src={course.cover_image} />
            </div>

            <div className='inner_course_header'>

              <div className='title'>{ course.title }</div>
              

              <div className='evaluation_like'>
                {evaluation !== null ?
                  Object.keys(evaluation).length ?
                  <ButtonStyled
                        
                    className={`bt_default bt_evaluation ${evaluationStatus === "complete" ? ' approved' : evaluationStatus === "blocked" ? ' disabled' : ''}`}
                    onClick={() => { (evaluationStatus === "active") && handleOpenEvaluation(course._id) }}
                  >
                    <p>Evaluar curso completo</p>

                    {evaluationStatus === "complete" ?
                    <div className='icon'>
                      <AiOutlineCheckCircle />
                    </div> : ""}

                  </ButtonStyled>
                  : ""
                :
                  <div className="loader">
                    <CircularProgress size={30} />
                  </div>
                }
                {/* <Like
                  className="box_like"
                  liked={course.status_liked}
                  likes={course.likes}
                  isLoading={isLoadingLike}
                  onClick={() => handleSetLike({id_curse: course._id})}
                /> */}
              </div>


              {/* <div className='evaluation_like'>
                {evaluation ?
                    evaluationStatus !== "complete" ?
                      <Fragment>

                        <ButtonStyled
                        
                          className={`bt_default bt_evaluation ${evaluationStatus === "complete" ? ' approved' : evaluationStatus === "blocked" ? ' disabled' : ''}`}
                          onClick={() => { (evaluationStatus === "active") && handleOpenEvaluation(course._id) }}
                        >
                          <p>Evaluar curso completo</p>

                          {evaluationStatus === "complete" ?
                          <div className='icon'>
                            <AiOutlineCheckCircle />
                          </div> : ""}

                        </ButtonStyled>

                        <Like
                          className="box_like"
                          liked={course.status_liked}
                          likes={course.likes}
                          isLoading={isLoadingLike}
                          onClick={() => handleSetLike({id_curse: course._id})}
                        />
                      </Fragment>
                    :
                    <Like
                      className="box_like"
                      liked={course.status_liked}
                      likes={course.likes}
                      isLoading={isLoadingLike}
                      onClick={() => handleSetLike({id_curse: course._id})}
                    />
                :
                <div className="loader">
                  <CircularProgress size={30} />
                </div>
                }
              </div> */}

              <div className='descripcion'>{ course.description_long }</div>

            </div>
          </div>



          <div className='course_modules'>
            <Content content={course} handleRefreshData={handleRefreshData} {...props}/>
          </div>
        </div>
      }


    </WrapperStyled>
  )
}

export default CourseContainer;
