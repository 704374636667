import React, { useState, useContext, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { path } from 'ramda';
import { ButtonYellow } from '../../../_elements';
import { Logo,
         Spinner } from '../../../_helpers/constants';
import { AuthContext } from '../../../_context/AuthContext';
import { ContentContext } from '../../../_context';
import storage from 'local-storage-fallback';
import { breakpoints } from '../../../_helpers/breakpoints';
import Content from '../../../_components/Training/Content';
import Like from '../../../_components/Training/Like';
import ModuleContent from '../../../_components/Training/ModuleContent';
import Breadcrumb from '../../../_components/Training/Breadcrumb';
import NavModules from '../../../_components/Training/NavModules';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@mui/material/Modal';

import { AiOutlineCheckCircle } from "react-icons/ai";

import Evaluation from '~/_components/Evaluation';

import { getModulesStatus,
         STATUS_COURSE_BLOCKED,
         STATUS_COURSE_UNLOCKED,
         STATUS_COURSE_VIEWED,
         STATUS_COURSE_COMPLETED,
         STATUS_COURSE_REPROVED,
         STATUS_COURSE_PENDING_CONNECTION,
         STATUS_COURSE_IN_PROGRESS } from '~/_helpers/commons';

const WrapperStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  >.loader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    svg{
      display: flex;
      color: #FFD200!important;
    }
  }
  .module{
    width: 100%;
    >.inner{
      display: flex;
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
      box-sizing: border-box;
      width: 100%;
      .module_header{
        display: flex;
        flex-direction: column;
        align-items: center;
        .image{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 220px;
          height: 220px;
          margin-right: 20px;
          margin-bottom: 20px;
          img{
            float: left;
            width: 100%;
          }
        }
        .inner_module_header{
          width: 100%;
          >.title{
            font-size: 25px;
            line-height: 29px;
            font-weight: 600;
            margin-bottom: 15px;
          }
          >.descripcion{
            margin-top: 15px;
          }
        }
      }

      >.module_content{
        margin-top: 40px;
        display: flex;
        justify-content: center;
      }
      >.module_nav{
        margin-top: 30px;
      }


      .evaluation_like{
        display: flex;
        flex-direction: row;
        align-items: center;
        .box_like{
          margin-right: 10px;
        }
        .last_evaluation_result{
          color: #556377;
          .value{
            font-weight: 600;
          }
        }
        .bt_evaluation{
          margin-right: 10px;
        }
        >.loader{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 100%;
          height: 45px;
          svg{
            display: flex;
            color: #FFD200!important;
          }
        }
      }
      p{
        padding: 0;
        margin: 0;
      }
    }
  }

  .container_features{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 30px;
  }
  .container_categories{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .module_modules{
    width: 100%;
    margin-top: 20px;
  }
  .module{
      display: flex;
    }
  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {
    .module{
      display: flex;
    }
  }
  @media ${breakpoints.md} {



    .module{
      width: 100%;
      >.inner{
        display: flex;
        flex-direction: column;
        padding-left: 25px;
        padding-right: 0;
        box-sizing: border-box;
        width: 100%;
        .module_header{
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          .image{
            width: 140px;
            height: 140px;
            margin-right: 20px;
            img{
              float: left;
              width: 100%;
            }
          }
          .inner_module_header{
            width: calc(100% - 160px);
            margin-left: ${props => props.haveCover ? '0' : '25px'};
            >.title{
              font-size: 25px;
              line-height: 29px;
              font-weight: 600;
              margin-bottom: 15px;
            }
            >.descripcion{
              margin-top: 15px;
            }
          }
        }

        >.module_content{
          justify-content: flex-start;
        }

        .evaluation_like{
          display: flex;
          flex-direction: row;
          align-items: center;
          .box_like{
            margin-right: 10px;
          }
          .bt_evaluation{
            margin-right: 10px;
          }
          >.loader{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            height: 45px;
          }
        }

      }
    }




    /* .module{
      width: 100%;
      >.inner{
        padding-left: 60px;
        width: calc(100% - 60px);
        >.module_content{
          margin-top: 30px;
          justify-content: flex-start;
        }
        >.module_nav{
          margin-top: 30px;
        }
        >.descripcion{
          margin-top: 20px;
        }
        >.title{
          font-size: 25px;
          line-height: 29px;
          margin-bottom: 15px;
        }
        .evaluation_like{
          display: flex;
          flex-direction: row;
          align-items: center;
          .box_like{
            margin-right: 10px;
          }
          .bt_evaluation{
            margin-right: 10px;
          }
          >.loader{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            height: 45px;
          }
        }
      }
    } */

  }
  @media ${breakpoints.lg} {

  }
`

const ButtonStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  height: 45px;
  background-color: #FFC600;
  border: 1px solid #FFC600;
  box-sizing: border-box;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
  >.icon{
    display: flex;
    font-size: 21px;
    margin-left: 5px;
  }
  p{
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    color: #292929;
    padding: 0;
    margin: 0;
  }
  :hover{
    border: 1px solid #292929;
    background-color: #FFFFFF;
  }
  &.approved{
    cursor: default;
    background-color: #FFFFFF;
    border: 1px solid #292929;
  }
  &.disabled{
    cursor: default;
    background-color: #FFFFFF;
    border: 1px solid #292929;
  }
`

const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
`

const ModuleContainer = (props) => {

  const { history, location, match } = props;

  const { login,
          isAuthLoading,
          loginError } = useContext(AuthContext);

  const { campus, 
          setCampus,
          getCampus,
          gotoData,
          setGotoData,
          trainingHome,
          setTrainingHome,
          navLoading,
          get_course_by_id,
          get_training_home,
          course,
          setCourse,
          get_content_by_id,
          module,
          setModule,
          evaluationActive,
          setEvaluationActive,
          evaluationData,
          setEvaluationData,
          set_add_like,
          crumbs,
          setCrumbs,
          take_user_evaluation,
          save_content_viewed } = useContext(ContentContext);


  const [ user, setUser ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ isLoading, setIsLoading ] = useState(false);
  const [ showLogin, setShowLogin ] = useState(true);
  const [ showRetrieve, setShowRetrieve ] = useState(false);
  const [ errorFields, setErrorFields ] = useState({user: false, password: false});
  const [ evaluation, setEvaluation ] = useState(null);
  const [ isLoadingLike, setIsLoadingLike ] = useState(false);
  const [ evaluationOpen, setEvaluationOpen ] = useState(false);
  const [ evaluationStatus, setEvaluationStatus ] = useState(null);
  const [ doesntHaveArchives, setDoesntHaveArchives ] = useState(null);

  useEffect(() => {
    console.log("match.params", match.params)
  
    return () => { }
  }, [match.params])
    

  useEffect(() => {

    if(!campus){
      getCampus();
    }else{
      if(match.params.id_course){
        console.log("campus", campus);
        const course_splitted = match.params.id_course.split(":");
        const courses = campus.categories.filter(category => category._id === course_splitted[0]);
        console.log("-> course", courses);
        if(courses.length){
          const course_filtered = courses[0].repository.filter(course => course._id === course_splitted[1]);
          console.log("course_filtered", course_filtered)
          if(course_filtered.length){
            const module_filtered = course_filtered[0].moduls.filter(module => module._id === match.params.id_module);
            console.log("module_filtere", module_filtered)
            if(module_filtered.length){
              console.log("module_filtere[0]", module_filtered[0])
              setModule(module_filtered[0])

              const evaluation = module_filtered[0].evaluations;
              if(evaluation.length){
                setEvaluation(evaluation[0]);
              }else{
                setEvaluation([])
              }
              if(module_filtered[0].status === 3){
                setEvaluationStatus("complete");
              }else{
                
                if(module_filtered[0].progressive_mode){
                  if(module_filtered[0].moduls.length){
                    const modules_pendings = module_filtered[0].moduls.filter(module => module.status === 0);
                    if(modules_pendings.length){
                      setEvaluationStatus("blocked");
                    }else{
                      setEvaluationStatus("active");
                    }
                  }else{
                    setEvaluationStatus("blocked");
                  }

                }
                
                //assessment_approved
                
              }

            }
            /*
            
            if(module_data.length){
              console.log("module_data", module_data)
            }
            */

            setCourse(course_filtered[0]);
          }
          
        }
       
      }
      
    }

    return () => {}
  }, [campus, match.params.id_module])


  useEffect(() => {
    //setEvaluation(null);
    //setModule(null);
    //setIsLoading(true);
    /*
    if(!course){
      get_content_by_id(match.params.id_module);
    }else{
      getModuleByState(course.moduls, match.params.id_module);
    }
      */
    return () => {}
  }, [match.params.id_module])

  useEffect(() => {

    /*
    if(match.params.id_module){
      if(!match.params.id_course){
        get_training_home();
      }
    }

    if(!course && match.params.id_course){
      setIsLoading(true);
      get_course_by_id(match.params.id_course);
    }
    */

    return () => {}
  }, [])

  useEffect(() => {
    return () => {
      setModule(null);
      setCourse(null);
      setCrumbs([]);
    }
  }, [])

  useEffect(() => {

    /*
    if(trainingHome && !match.params.id_course){
      let _id_course = null;
      trainingHome.categories.map((cat, index) => {
        cat.repository.map((rep, index_rep) => {
          rep.moduls.map((module, index_module) => {
            if(module._id === match.params.id_module){

              get_course_by_id(rep._id);
              _id_course = rep._id;
              return null;
            }
            if(_id_course){ return null };
          })
          if(_id_course){ return null };
        })
        if(_id_course){ return null };
      })
    }
    */

    return () => {}
  }, [trainingHome])


  useEffect(() => {
    
    if(module && course){
      setIsLoading(false);
      handleCrumb();
    }
    
    return () => {}
  }, [module, course])

  const handleCrumb = () => {
    setCrumbs([{_id: null, label: "Campus", type: "section", route: `/campus`},
               {_id: course._id, label: course.title, type: "moducoursele", route: `/campus/course/${match.params.id_course ? match.params.id_course : course._id}`},
               {_id: module._id, label: module.title, type: "module", route: `/campus/course/${match.params.id_course ? match.params.id_course : course._id}/module/${module._id}`}])
  }

  const getModuleByState = (modules, id_module) => {
    const module_filtered = modules.filter(module => module._id == id_module);
    if(module_filtered.length){
      setModule(module_filtered[0]);
    }
  }

  const handleOpenEvaluation = (id) => {
    setEvaluationData({type: "module", id: id});
    setEvaluationOpen(true);
  }

  const handleSetLike = (payload) => {
    setIsLoadingLike(true);
    const response = set_add_like(payload);
    response.then((res) => {
      setIsLoadingLike(false);
      const modules_edited = course.moduls.map((m, i) => {
        if(m._id == payload.id_content){
          let new_likes = m.likes;
          if(m.status_liked){
            new_likes = new_likes - 1;
            m.status_liked = false;
          }else{
            new_likes = new_likes + 1;
            m.status_liked = true;
          }
          m.likes = new_likes;
          setModule(m);
        }
        return m;
      })
      const course_edited = {...course};
      course_edited.moduls = modules_edited;
      setCourse(course_edited);
    })
  }

  useEffect(() => {
    console.log("- module", module)
    if(module){
      //handleTakeEvaluation();
    }
    return () => {}
  }, [module])

  const handleTakeEvaluation = () => {
    /*
    const response = take_user_evaluation(module._id, "");
      response.then((res) => {
        setEvaluation(path(['data', 'single'], res));
      })
    */
  }

  const handleRefreshData = (payload) => {

    console.log("-*-*-*-*- payload", payload)
    let module_arr = JSON.parse(payload.moduls);
    let module_parsed = JSON.parse(payload.moduls);
    let module_parsed_filter = module_parsed.filter(mod => mod._id === match.params.id_module);
    if(module_parsed_filter.length){
      module_parsed = module_parsed_filter;
    }
    //return null;
    console.log("%%%%%%%%%%%%%%", module_arr);
    const course_splitted = match.params.id_course.split(":");

    const campus_update = campus.categories.map(category => {

      if(course_splitted[0] === category._id){

        const repository_update = category.repository.map(repo => {

          if(repo._id === course_splitted[1]){
            let active_next_index = null;
            const module_update = repo.moduls.map(module => {

              for (let i = 0; i < module_arr.length; i++) {
                console.log("############## ", module_arr[i]._id +"==="+ module._id)
                if(module_arr[i]._id === module._id){
                  module.status = module_arr[i].status;
                }
                
              }
              /*
              if(module._id === module_parsed[0]._id){
                console.log("xx repo", repo)
                if(repo.progressive_mode){
                    active_next_index = parseInt(module.index) + 1;
                }
                module.status = module_parsed[0].status;
              }
              console.log("active_next_index", active_next_index)
              
              if(active_next_index){
                if(active_next_index <= repo.moduls.length){
                  if(module.index === active_next_index){
                    console.log("STATUS_COURSE_UNLOCKED", module)
                    module.status = STATUS_COURSE_UNLOCKED;
                  }
                }
              }
              */
              
              return module;
            })

            

            console.log("++ repo", repo)

            const status = payload.status;//getModulesStatus(repo);
            repo.status = status;

          }

          

          return repo;

        })
        console.log("categopru", category)
      }

      return category;
    })

    const campus_update_arr = {...campus};
    campus_update_arr.categories = campus_update;
    setCampus(campus_update_arr);

    console.log("\u{1F9E1} campus_update", campus_update)
    /*
    const courses = campus.categories.filter(category => category._id === course_splitted[0]);
    if(courses.length){
      const course_filtered = courses[0].repository.filter(course => course._id === course_splitted[1]);
      if(course_filtered.length){
        const module_filtered = course_filtered[0].moduls.filter(module => module._id === match.params.id_module);
        if(module_filtered.length){
          setModule(module_filtered[0])
        }
        setCourse(course_filtered[0]);
      }
      
    }
    */

    //setNavLoading(true);
    //const response =
    //get_course_by_id(match.params.id_course);
    // response.then(res => {
    //   setNavLoading(false);
    // })
  }

  

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setEvaluationOpen(false)
    }
  }

  const handleOpen = () => {
    setEvaluationOpen(true);
  }

  useEffect(() => {

    if(course){
      let count_have_archives = 0;
      course.moduls.map((module, index) => {
        module.media_slides.map((media, index) => {
          if(media.content_type === "Archives" || media.content_type === "Pdf"){
            count_have_archives = count_have_archives + 1;
          }
        })
      })
      //console.log("count_have_archives >= course.moduls.length", count_have_archives +">="+ course.moduls.length)
      if(count_have_archives >= course.moduls.length){
        //console.log("setDoesntHaveArchives FALSE")
        setDoesntHaveArchives(false);
      }else{
        //console.log("setDoesntHaveArchives TRUE")
        setDoesntHaveArchives(true);
      }
    }

    return () => {}
  }, [course])

  useEffect(() => {
    console.log("evaluation -> ", evaluation)
  
    return () => {}
  }, [evaluation])
  

  return (
    <WrapperStyled
      haveCover={ module?.cover_image ? true : false }
    >

      <ModalStyled
        open={evaluationOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        //onBackdropClick={(event) => {event.stopPropagation(); return false;}}
        disableEscapeKeyDown
      >
        <Fragment>
          {evaluationData &&
            <Evaluation
              _id={match.params.id_module}
              scope={"module"}
              title={module.title}

              parentData={module}
              evaluation={evaluation}
              setEvaluation={setEvaluation}
              handleRefreshData={handleRefreshData}

              evaluationData={evaluationData}
              setEvaluationData={setEvaluationData}
              handleClose={handleClose}
            />
          }
        </Fragment>
      </ModalStyled>

      <Breadcrumb
        crumbs={crumbs}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        {...props}
      />

      {module == null ?
        <div className="loader">
          <CircularProgress size={30} />
        </div>
        :
        <div className='module'>
          <div className='inner'>

            <div className='module_header'>
              <div className='image'>
                <img src={module.cover_image} />
              </div>

              <div className='inner_module_header'>

                <div className='title'>{ module.title }</div>

                <div className='evaluation_like'>

                    {evaluation !== null ?

                      Object(evaluation).keys.length ?
                      <ButtonStyled
                        className={`bt_default bt_evaluation ${evaluationStatus === "complete" ? ' approved' : evaluationStatus === "blocked" ? ' disabled' : ''}`}
                        onClick={() => { (evaluationStatus === "active") && handleOpenEvaluation(course._id) }}
                      >
                        <p>Evaluarme</p>

                        {evaluationStatus === "complete" ? //evaluation.status_last_evaluation === 1 ?
                        <div className='icon'>
                          <AiOutlineCheckCircle />
                        </div> : ""}

                      </ButtonStyled> : ""

                    :

                      <div className="loader">
                        <CircularProgress size={30} />
                      </div>

                    }

                    {/* <Like
                      className="box_like"
                      liked={module.status_liked}
                      likes={module.likes ? module.likes : 0}
                      isLoading={isLoadingLike}
                      onClick={() => handleSetLike({id_content: module._id})}
                    /> */}

                </div>

                {/* <div className='evaluation_like'>
                  {evaluation === null ?
                      evaluationStatus !== "complete" ?
                        <Fragment>
                          <ButtonStyled
                            className={`bt_default bt_evaluation ${evaluation.status_last_evaluation === 1 ? ' approved' : !evaluation.enabled ? ' disabled' : ''}`}
                            onClick={() => { (evaluation.status_last_evaluation !== 1 && evaluation.enabled) && handleOpenEvaluation(module._id) }}
                          >
                            <p>Evaluarme</p>

                            {evaluationStatus === "complete" ? //evaluation.status_last_evaluation === 1 ?
                            <div className='icon'>
                              <AiOutlineCheckCircle />
                            </div> : ""}

                          </ButtonStyled>
                          <Like
                            className="box_like"
                            liked={module.status_liked}
                            likes={module.likes ? module.likes : 0}
                            isLoading={isLoadingLike}
                            onClick={() => handleSetLike({id_content: module._id})}
                          />
                        </Fragment>
                      :
                      <Like
                        className="box_like"
                        liked={module.status_liked}
                        likes={module.likes ? module.likes : 0}
                        isLoading={isLoadingLike}
                        onClick={() => handleSetLike({id_content: module._id})}
                      />
                  :
                  <div className="loader">
                    <CircularProgress size={30} />
                  </div>
                  }
                </div> */}

                <div className='descripcion'>{ module.description_long }</div>
              </div>
            </div>
            {module && (module.media_slides.filter(media => (media.content_type != 'Archives' && media.content_type != 'Pdf'))).length ?
            <div className='module_content'>
              <ModuleContent
                course={course}
                elements={module.media_slides.filter(media => (media.content_type != 'Archives' && media.content_type != 'Pdf'))/*module.media_slides*/}
                module={module}
                handleTakeEvaluation={handleTakeEvaluation}
                handleRefreshData={handleRefreshData}
              />
            </div> : ""
            }

            {(course && (course.moduls.length > 1 && doesntHaveArchives)/*course.moduls.length > 1*/) ?
            <div className='module_nav'>
              <NavModules
                course={course}
                module={module}
                setModule={setModule}
                navLoading={navLoading}
                {...props}
              />
            </div> : ""}

          </div>
        </div>
      }


    </WrapperStyled>
  )
}

export default ModuleContainer;
