import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import { ContentContext } from '../../_context';
import { path } from 'ramda';
import { SideLeft, SideRight, DashboardStats, CatalogueItem } from '../../_components';
import { breakpoints } from '../../_helpers/breakpoints';
import { Modal, Fade, Backdrop, Box } from '@material-ui/core';
import { RiCloseCircleLine } from "react-icons/ri";
import { Spinner, COLORS } from '../../_helpers/constants';
import LazyLoad from 'react-lazyload';
import CircularProgress from '@material-ui/core/CircularProgress';
import SubCategories from './SubcategoriesContainer';
import Products from './ProductsContainer';
import Product from './ProductContainer';

const Wrapper = styled.div`

  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  //padding-top: 15px;
  padding-bottom: 15px;
  .products_list{
    width: 100%;
  }
  .category_list,
  .category_list_inner{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    .label{
      width: 80px;
      margin-right: 5px;
      padding-top: 8px;
    }
    .bt_cagegory{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 2px solid #EAEAF0;
      border-radius: 12px;
      width: 100%;//calc(50% - 5px);
      box-sizing: border-box;
      padding: 5px 5px;
      /* margin-right: 15px; */
      margin-bottom: 0;
      cursor: pointer;
      &.is_image{
        //height: 150px;
      }
      img{
        float: left;
        width: 100%;
      }
      :hover{
        border: 2px solid #FFC600;
      }
      .title{
        font-weight: 600;
        font-size: 18px;
        white-space: nowrap;

      }
      &.selected{
        border: 2px solid #FFC600;
      }
    }

    .list_items{
      /* display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: calc(100% - 10px); */

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      width: 100%;


      .bt_cagegory_inner{
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 6px;
        font-size: 13px;
        font-weight: 600;
        background-color: #DADADA;
        box-sizing: border-box;
        padding: 5px 10px;
        color: #000000;
        margin-left: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        &.selected{
          background-color: #FFC600;
        }
        :hover{
          background-color: #08081B;
          color: #FFFFFF;
        }
        .title{
          white-space: nowrap;
        }
      }
    }
  }
  .category_list{
    justify-content: space-between;
  }
  .category_list_inner{
    margin-top: 10px;

  }
  .subcategory_list{
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  >.loader{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 15px;
    svg{
      display: flex;
      color: #FFD200!important;
    }
  }

  @media ${breakpoints.xs} {
    .category_list,
    .category_list_inner{
      .bt_cagegory{
        width: 100%;
      }
    }
  }
  @media ${breakpoints.sm} {
    .category_list,
    .category_list_inner{
      .list_items{
        /* display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: calc(100% - 85px); */
        grid-template-columns: repeat(3, 1fr);
        .bt_cagegory_inner{
          border-radius: 6px;
          font-size: 16px;
          background-color: #DADADA;
          padding: 10px 20px;
          color: #000000;
          margin-left: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
  @media ${breakpoints.md} {
    .category_list,
    .category_list_inner{
      .bt_cagegory{
        /* width: 200px;
        padding: 5px 5px;
        margin-right: 15px;
        margin-bottom: 15px; */
      }
    }
    .category_list{
      //justify-content: flex-start;
    }
  }
  @media ${breakpoints.lg} {
    .category_list,
    .category_list_inner{
      .list_items{
        /* display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: calc(100% - 85px); */
        grid-template-columns: repeat(4, 1fr);
        .bt_cagegory_inner{
          border-radius: 6px;
          font-size: 16px;
          background-color: #DADADA;
          padding: 10px 20px;
          color: #000000;
          margin-left: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
  @media ${breakpoints.xl} {
    .category_list,
    .category_list_inner{
      .list_items{
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
  @media ${breakpoints.xxxl} {
    .category_list,
    .category_list_inner{
      .list_items{
        grid-template-columns: repeat(6, 1fr);
      }
    }
  }
  @media ${breakpoints.xxxl} {
    .category_list,
    .category_list_inner{
      .list_items{
        grid-template-columns: repeat(7, 1fr);
      }
    }
  }
`

const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #CCCCCC!important;
  }
`

const CatalogoContainer = () => {

  let { id_category } = useParams();

  const { loading,
          ecommerce_products,
          ecommerce_brands,
          ecommerce_category,
          categoriesBrand,
          setCategoriesBrand,
          categoriesSelected,
          setCategoriesSelected,
          product,
          setProduct,
          products,
          setProducts } = useContext(ContentContext);

  const [ productsBrand, setProductsBrand ] = useState(null);

  const [ brandSelected, setBrandSelected ] = useState(null);
  const [ categorySelected, setCategorySelected ] = useState(null);
  const [ productSelectedData, setProductSelectedData ] = useState(null);
  const [ openModalProduct, setOpenModalProduct ] = useState(false);

  const [ isLoadingProducts, setIsLoadingProducts ] = useState(false);
  // const [ categoriesBrand, setCategoriesBrand ] = useState(null);
  // const [ categoriesSelected, setCategoriesSelected ] = useState([]);
  // const [ products, setProducts ] = useState([]);

  useEffect(() => {

    if(!categoriesBrand){
      const brands_response = ecommerce_brands();
      brands_response.then((res) => {
        let brands = path(['data', 'single', 'categories'], res);
        setCategoriesBrand(brands);//.reverse()
        //console.log("categoriesBrand", res)
      })
    }

    return () => {}
  }, [categoriesBrand])

  const handleSubcategories = (categories, category_id, level) => {
    
    // console.log("- - - - - - - - - - - - - - - - - - -")
    // console.log("categories", categories)
    // console.log("category_id", category_id)
    // console.log("level", level)
    // console.log("categories.products", categories.products)

    setProducts([]);

    setTimeout(() => {
      setProducts(categories.products);
    }, 300);
    

    //console.log("- - - - - - - - - - - - - - - - - - -")

    
    // console.log("category_id", category_id)
    // console.log("categories", categories)
    // console.log("categoriesSelected[level", categoriesSelected[level])
    //setCategorySelected(categories);
    
    //console.log("-> LEVEL", level)


    if(categoriesSelected.length){
      //console.log("categoriesSelected.length", categoriesSelected.length)
      if(categoriesSelected.length >= 1){

        const categoriesSelected_update = [...categoriesSelected];
        for(let n=0; n<=level-1; n++){
          // console.log("-> categoriesSelected_update", categoriesSelected_update[n]);
          // console.log("SUB CATEGORIA")
        }
        categoriesSelected_update[level] = categories;
        setCategoriesSelected(categoriesSelected_update);

      }else{
        setCategoriesSelected([categories]);
      }
    }else{
      setCategoriesSelected([categories]);
    }

    

    /*
    if(categoriesSelected.length){
      if(categoriesSelected[level] !== undefined){
        if(categoriesSelected[level]._id === category_id){
          setCategoriesSelected([])
        }else{
          //console.log("categoriesSelected[level]._id === category_id", categoriesSelected[level]._id +"==="+ category_id)

          const categories_updated = categoriesSelected.map((category, index) => {
            if(index < level){
              return category;
            }
            if(index === level){
              return categories;
            }
          })
          setCategoriesSelected(categories_updated);
        }
      }else{
        setCategoriesSelected(prev => [...prev, categories]);
      }
    }else{
      setCategoriesSelected(prev => [...prev, categories]);
    }
    */






    //console.log("categories.categories --> ", categories.categories)
    if(!categories.categories.length){
      //alert("productos")
      handleProducts(category_id);
    }

  }


  const handleProducts = (category_id, products) => {
    // console.log("* - - - - - - - - - - - - - - - - - - - *")
    // console.log("categoriesBrand", categoriesBrand)
    // console.log("category_id", category_id)
    // console.log("categoriesSelected", categoriesSelected)
    // console.log("products", products)
    // console.log("* - - - - - - - - - - - - - - - - - - - *")

    setProducts(products);

    return null;
    
    setIsLoadingProducts(true);
    const payload = {category_id: category_id};
    const response = ecommerce_products(payload);
    response.then((res) => {
      setIsLoadingProducts(false);
      setProducts(res.data.single.products);
      //console.log("handleProducts", res)
    })
    //console.log("handleProducts", category_id)
  }

  const handleCategorySelected = (category_id) => {
    //console.log("asd")
    const exist = categoriesSelected.filter(c => c._id === category_id);
    //console.log("category_id", category_id, categoriesSelected, exist.length ? true : false)
    return exist.length ? true : false;

    // console.log("categorySelected: ", categorySelected);
    // return false;
    /*
    if(categorySelected !== null){
      if(categorySelected._id === category_id){
        //const exist = categorySelected.filter(c => c._id === category_id);
        //console.log("exist: ", exist)
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
    */
  }



  useEffect(() => {

    //console.log("--->>>>>>> categoriesBrand", categoriesBrand)
    if(categoriesBrand){
      const categoriesSelected_update = [...categoriesSelected];

      categoriesSelected_update[0] = categoriesBrand[0];
      categoriesSelected_update[1] = categoriesBrand[0].categories[0];

      setCategoriesSelected(categoriesSelected_update);
      handleProducts(categoriesBrand[0].categories[0], categoriesBrand[0].categories[0].products);
    }

    // if(categoriesSelected.length >= 1){

    //   const categoriesSelected_update = [...categoriesSelected];
    //   for(let n=0; n<=level-1; n++){
    //     console.log("-> categoriesSelected_update", categoriesSelected_update[n]);
    //     console.log("SUB CATEGORIA")
    //   }
    //   categoriesSelected_update[level] = categories;
    //   setCategoriesSelected(categoriesSelected_update);

    // }else{
    //   setCategoriesSelected([categories]);
    // }



    return () => {}
  }, [categoriesBrand])


  return (
    <Wrapper>

      {categoriesBrand === null ?
        <div className="loader">
          <CircularProgress size={30} />
        </div>
      :
        !product ?
        <Fragment>
          <div className='category_list'>
            {(categoriesSelected && !categoriesSelected.length) && categoriesBrand && categoriesBrand.map((category, index) => {
              return(
                <div className={`bt_cagegory${category.image ? ' is_image' : ''}`} onClick={() => handleSubcategories(category, category._id, category.level)/*category.categories.length ? handleSubcategories(category, category._id, category.level) : handleProducts(category, category._id)*/}>
                  {category.image ?
                  <img src={`${category.image}`} />
                  :
                  <div className="title">{category.name}</div>
                  }
                </div>
              )
            })}
          </div>

          {categoriesSelected.length > 0 ?
          <div className='category_list_inner'>
            {/* <div className="label">Categorías</div> */}
            <div className='list_items'>
              {categoriesBrand && categoriesBrand.map((category, index) => {
                return(
                  <div className={`bt_cagegory${category.image ? ' is_image' : ''}${handleCategorySelected(category._id) ? ' selected' : ''}`} onClick={() => handleSubcategories(category, category._id, category.level)/*category.categories.length ? handleSubcategories(category, category._id, category.level) : handleProducts(category, category._id)*/}>
                    {category.image ?
                    <img src={`${category.image}`} />
                    :
                    <div className="title">{category.name}</div>
                    }
                  </div>
                  // <div className={`bt_cagegory_inner ${handleCategorySelected(category._id) ? ' selected' : ''}`} onClick={() => handleSubcategories(category, category._id, category.level)/*category.categories.length ? handleSubcategories(category, category._id, category.level) : handleProducts(category, category._id)*/}>
                  //   <div className="title">{category.name}</div>
                  // </div>
                )
              })}
            </div>
          </div> : ""}

          <div className='subcategory_list'>
            {categoriesSelected.length > 0 && categoriesSelected.map((category, index) => {
              if(!category.categories.length){ return null; }
              return(
                <SubCategories
                  categories={category.categories}
                  handleSubcategories={handleSubcategories}
                  handleProducts={handleProducts}
                  handleCategorySelected={handleCategorySelected}
                />
              )
            })}
          </div>

          {!isLoadingProducts ?
            products && products.length ?
            <div className='products_list'>
              <Products
                products={products}
              />
            </div> : ""
            :
            <div className="loader">
              <CircularProgress size={30} />
            </div>
          }
        </Fragment>
      :
      <Product
        handleSubcategories={handleSubcategories}
        categoriesBrand={categoriesBrand}
      />
      }


    </Wrapper>
  )
}

export default CatalogoContainer;
