import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { ButtonYellow } from '../../_elements';
import { Spinner } from '../../_helpers/constants';
import { AuthContext } from '../../_context/AuthContext';
import { AiFillEyeInvisible,
         AiFillEye } from "react-icons/ai";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*background-color: #F5F6FA;*/
`
const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &.field_password{
    margin-top: 15px;
    position: relative;
    .password_show{
      width: 50px;
      right: 0;
      bottom: 0;
      text-align: center;
      position: absolute;
      font-size: 30px;
      line-height: 41px;
      padding-bottom: 1px;
      cursor: pointer;
      :hover{
        color: #FFD200;
      }
    }
  }
`
const Label = styled.label`
  display: flex;
  width: 100%;
  margin-bottom: 3px;
  color: #242424;
`
const Input = styled.input`
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
  border: 1px solid #edeef0;
  ::placeholder {
    color: #a8a8a8;
    font-size: 16px;
  }
  :focus{
    outline: none;
  }
  *:focus {
    outline: none;
  }
  &.error{
    border: 1px solid red!important;
  }
`
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .loader{
    margin-top: 15px!important;
    height: 40px;
  }
`
const ErrorMessage = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3px;
  p{
    color: #E02020;
    width: 100%;
    font-size: 14px;
    text-align: right;
  }
`

const LoginContainer = () => {

  const { login,
          isAuthLoading,
          loginError,
          signIn } = useContext(AuthContext);

  const [ user, setUser ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ passwordShow, setPasswordShow ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [ errorFields, setErrorFields ] = useState({user: false, password: false});

  const handleFormSubmit = async () => {

    if(!user){

    }
    if(!password){

    }

    setErrorFields({user: !user ? true : false, password: !password ? true : false})

    if(!user || !password){

      setLoading(false);
      return null;

    }

    setLoading(true);

    try {
      //const result = await login(user, password);
      const result = await signIn(user, password);

      result.then((res) => {
        setLoading(false);
      })


    } catch (e) {

    }

  };


  return (
    <Wrapper>

          <Field>
            <Label>D.N.I.:</Label>
            <Input
              type="text"
              placeholder="Ingresá tu D.N.I."
              value={user}
              className={errorFields.user ? 'error' : '' }
              onChange={(e) => setUser(e.currentTarget.value)}
            />
          </Field>

          <Field className="field_password">
            <Label>Contraseña:</Label>
            <Input
              type={passwordShow ? 'text' : 'password'}
              placeholder="Ingresá tu Contraseña."
              value={password}
              className={errorFields.password ? 'error' : '' }
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            <div className="password_show" onClick={() => setPasswordShow(!passwordShow)}>
                { passwordShow ? <AiFillEyeInvisible /> : <AiFillEye /> }
            </div>
          </Field>

          {loginError &&
            <ErrorMessage>
              <p>Hay un error en el D.N.I o contraseña ingresada.</p>
            </ErrorMessage>
          }

          <Actions>

            {isAuthLoading ?
            <Spinner className="loader" />
            :
            <ButtonYellow className="bt_login" onClick={handleFormSubmit}>
              <p>ENVIAR</p>
            </ButtonYellow>
            }

          </Actions>

    </Wrapper>
  )
}

export default LoginContainer;
