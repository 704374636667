import styled from "styled-components";
import { breakpoints } from "~/_helpers/breakpoints";
import Snackbar from '@mui/material/Snackbar';
import Menu from '@mui/material/Menu';

export const SnackbarStyled = styled(Snackbar)`
    &.success{
        .MuiPaper-root{
            background-color: green!important;
        }
    }
    &.error{
        .MuiPaper-root{
            background-color: red!important;
        }
    }
`
export const HiddenInput = styled.input`
    display: none;
`;
export const MenuStyled = styled(Menu)`
    .MuiMenu-paper{
        background-color: #B5AF99;
        color: #FFFFFF;
    }
`
export const WrappedStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* background-color: #353535; */
    color: #FFFFFF;
    padding-top: 35px;
    padding-bottom: 60px;
    font-family: 'ShellHeavy'!important;
    //font-family: 'ShellHeavy'!important;
    //font-family: 'ShellBook'!important;
    //font-family: 'ShellBold'!important;
    .error_video_size{
        color: red;
        margin-top: 15px;
    }
    .novideos{
        width: 100%;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .baristas_lineas{
        display: none;
        position: absolute;
        left: 0;
        top: 0;
    }
    .baristas_lineas_r{
        display: none;
        position: absolute;
        top: 0;
        right: 0;
    }
    .box_voting_pending{
        margin-top: 150px;

        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 30px);
        margin-top: 150px;
        z-index: 999;
        .inner{
            border: 2px solid #AADBE9;
            width: 100%;
            border-radius: 20px;
            padding: 25px;
            background-color: #353535;
            margin-top: 15px;

            .text{
                font-size: 22px;
                line-height: 28px;
                color: #AADBE9;
                text-align: center;
            }
        }
        .voting_pending_text{
            margin-top: 15px;
            text-align: center;
            color: #AADBE9;
            font-size: 16px;
            line-height: 22px;
        }
    }
    .box_voting,
    .box_select_barista,
    .box_upload_video_etapa1,
    .box_voting_pending,
    .box_approve_video{
        >.actions{
            display: flex;
            flex-direction: column;
            margin-top: 25px;
            .bt_instructivo,
            .bt_reglamento,
            .bt_faq{
                font-size: 20px;
                line-height: 20px;
                padding-left: 25px;
                padding-right: 25px;
                height: 51px;
                text-align: center;
            }
            .bt_faq{
                margin-left: 0;
                margin-top: 10px;
            }
            .bt_reglamento{
                margin-left: 0;
                margin-top: 10px;
                color: #3C3C3B;
                p{
                    text-shadow: 0 0 0 rgba(0,0,0,0.6);
                }
                :hover{
                    color: #FFFFFF;
                    p{
                        text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
                    }
                }
            }
        }
    }
    .box_voting{
        margin-top: 150px;

        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 30px);
        margin-top: 150px;
        z-index: 999;
        .inner{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 30px;
            .intro_text{
                width: 100%;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 20px;
                color: #AADBE9;
            }
            .box_video_list{
                width: 100%;
                .video_elements{
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    grid-gap: 20px;
                    align-items: start;
                    .video_element{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        width: 100%;
                        background-color: #353535;
                        -webkit-box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.5);
                        -moz-box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.5);
                        box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.5);
                        border-radius: 12px;
                        overflow: hidden;
                        .video{
                            //width: 100%;
                            background-color: #000000;
                            width: 95vw;
                            height: 53.3vw;
                        }
                        .data{
                            display: flex;
                            flex-direction: row;
                            align-items: flex-end;
                            justify-content: space-between;
                            padding: 15px;
                            .vote_check{
                                color: #AADBE9;
                                font-size: 35px;
                            }
                            .label{
                                color: #B5AF99;
                                font-family: 'ShellBook'!important;
                                font-size: 15px;
                                line-height: 22px;
                                font-weight: 600;
                            }
                            .value{
                                color: #AADBE9;
                                font-size: 35px;
                                line-height: 40px;
                                font-family: 'ShellBold'!important;
                            }
                            .actions{
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                margin-top: 5px;
                                .bt_approve,
                                .bt_disapprove{
                                    font-size: 16px;
                                    line-height: 16px;
                                    font-weight: 500;
                                    padding-left: 15px;
                                    padding-right: 15px;
                                    height: 41px;
                                    &.blocked{
                                        opacity: 0.3;
                                    }
                                }
                                .bt_disapprove{

                                }
                            }
                        }
                    }
                }

            }
        }
    }
    .box_upload_video_etapa1{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 30px);
        margin-top: 150px;
        z-index: 999;
        .inner{
            position: relative;
            border: 2px solid #AADBE9;
            width: 100%;
            border-radius: 20px;
            padding: 25px;
            background-color: #353535;
            margin-top: 20px;
            .video_block{
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border-radius: 20px;
                background-color: rgba(53, 53, 53, 0.75);
                .bt_condiciones{
                    border-radius: 6px;
                    background-color: #AADBE9;
                    padding: 20px 30px;
                    color: #353535;
                    font-size: 18px;
                    line-height: 22px;

                    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.6);
                    -moz-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.6);
                    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.6);
                    cursor: pointer;
                    :hover{
                        border: 1px solid #AADBE9;
                        background-color: #353535;
                        color: #AADBE9;
                    }
                }
            }
        }
        .upload_video_text{
            margin-top: 15px;
            text-align: center;
            color: #AADBE9;
            font-size: 16px;
            line-height: 22px;
        }
        .titulo_consigna{
            font-size: 16px;
            line-height: 22px;
            font-weight: 600;
            margin-bottom: 5px;
            color: #AADBE9;
        }
        .box_video_guide_etapa1{
            overflow: hidden;
            border-radius: 15px;
            //border: 2px solid transparent;
            //margin-top: 15px;
            /* width: 100%;
            height: 421px; */
            
            //width: 88vw;
            //height: 50vw;

            width: 80vw;
            height: 45vw;

            margin-bottom: 20px;
            background-color: #000000;
        }
        .box_uploader{
            display: flex;
            flex-direction: column;
            align-items: center;
            //margin-top: 20px;
            .box_video_loaded1{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                >.title{
                    color: #AADBE9;
                    font-size: 22px;
                    line-height: 28px;
                    font-weight: 600;
                }
                >.date{
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 600;
                    color: #B5AF99;
                    margin-top: 3px;
                }
                >.aclaration{
                    text-align: center;
                    font-family: 'ShellBook'!important;
                    font-size: 14px;
                    line-height: 20px;
                    //margin-top: 15px;
                    .highlight{
                        color: #AADBE9;
                    }
                }
                .box_rejected,
                .box_approved{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px dashed red;
                    padding: 10px 15px;
                    font-size: 16px;
                    line-height: 20px;
                    width: 100%;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    color: red;
                }
                .box_approved{
                    border: 1px dashed #AADBE9;
                    color: #AADBE9;
                }
                .text_pending{
                    text-align: center;
                    font-family: 'ShellBook'!important;
                    font-size: 14px;
                    line-height: 20px;
                    margin-top: 10px;
                    margin-bottom: 15px;
                }
            }
            .bt_select_file,
            .bt_upload_file{
                font-size: 20px;
                line-height: 20px;
                padding-left: 25px;
                padding-right: 25px;
                height: 51px;
                &.disabled{
                    opacity: .3;
                }
            }
            .bt_select_file{
                &.disabled{
                    cursor: default;
                    :hover{
                        //border: 2px solid #B5AF99;
                        background-color: #AADBE9;
                        color: #FFFFFF;
                        p{
                            text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
                        }
                    }
                }
            }
            .bt_upload_file{
                &.disabled{
                    cursor: default;
                    :hover{
                        //border: 2px solid #AADBE9;
                        background-color: #B5AF99;
                        color: #FFFFFF;
                        p{
                            text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
                        }
                    }
                }
            }
            .file_data{
                margin-top: 20px;
                margin-bottom: 20px;
                .name,
                .size{
                    display: flex;
                    flex-direction: column;
                    .label{
                        font-weight: 600;
                        color: #AADBE9;
                    }
                    .value{

                    }
                }
                .name{
                    .label{
                        width: 170px;
                        //background-color: pink;
                    }
                }
                .size{
                    margin-top: 10px;
                    .label{
                        width: 140px;
                        //background-color: pink;
                    }
                }
            }
            .data_progress{
                display: flex;
                flex-direction: column;
                align-items: center;
                /* margin-top: 20px; */
                >.loaging{
                    svg{
                        color: #AADBE9;
                    }
                }
                .progress_text{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .label{
                        font-weight: 600;
                        color: #B5AF99;
                    }
                    .value{
                        font-size: 55px;
                        line-height: 55px;
                        font-weight: 800;
                        color: #AADBE9;
                    }
                }
                .video_upload_response{
                    font-size: 18px;
                    line-height: 22px;
                    margin-top: 10px;
                    font-weight: 600;
                    color: #B5AF99;
                }
            }
        }
        
    }
    .box_welcome{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* position: absolute; */
        z-index: 99;
        margin-top: 150px;
        .welcome_video{
            overflow: hidden;
            border-radius: 12px;
            border: 2px solid #AADBE9;
            margin-top: 20px;
            width: 90vw;
            height: 51vw;
            background-color: #353535;
        }
        .welcome_text{
            color: #AADBE9;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            margin-top: 15px;
            width: calc(100% - 30px);
            text-align: center;
            background-color: #353535;
        }
        .actions{
            margin-top: 20px;
            cursor: pointer;
        }
    }
    .logo_top{
        position: absolute;
        width: 250px;
        background-color: #353535;
        z-index: 999;
        img{
            float: left;
            width: 100%;
        }
    }

    .actions{
        /*
        display: flex;
        flex-direction: row;
        background-color: aqua;
        .bt_base{
            cursor: pointer;
            background-color: bisque;
        }
        */
    }
    .box_approve_video{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 30px);
        margin-top: 150px;
        z-index: 999;
        .inner{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 15px;
            .intro_text{
                width: 100%;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 20px;
                color: #AADBE9;
            }
            .box_video_list{
                width: 100%;
                .video_elements{
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    grid-gap: 20px;
                    align-items: start;
                    .video_element{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        width: 100%;
                        background-color: #353535;
                        -webkit-box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.5);
                        -moz-box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.5);
                        box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.5);
                        border-radius: 12px;
                        overflow: hidden;
                        .video{
                            width: 95vw;
                            height: 53.3vw;
                            /*
                            width: 386px;
                            height: 300px;
                            */
                            background-color: #000000;
                            //width: 300px;
                            //height: 190px;
                            //height: 250px;
                        }
                        .data{
                            padding: 15px;
                            .title{
                                color: #AADBE9;
                                font-size: 16px;
                                line-height: 22px;
                                font-weight: 600;
                            }
                            .description{
                                font-size: 14px;
                                line-height: 20px;
                                font-family: 'ShellBook'!important;
                            }
                            .actions{
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                margin-top: 15px;
                                .bt_approve,
                                .bt_disapprove{
                                    font-size: 16px;
                                    line-height: 16px;
                                    font-weight: 500;
                                    padding-left: 15px;
                                    padding-right: 15px;
                                    height: 41px;
                                }
                                .bt_disapprove{

                                }
                            }
                            .box_shell_approve,
                            .box_shell_disapprove{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                border: 1px dashed red;
                                color: red;
                                margin-top: 15px;
                                padding: 5px;
                                font-size: 13px;
                                line-height: 18px;
                                font-family: 'ShellBook'!important;
                            }
                            .box_shell_approve{
                                border: 1px dashed #AADBE9;
                                color: #AADBE9;
                            }
                        }
                    }
                }

            }
        }
    }
    .box_select_barista{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 30px);
        /* position: absolute; */
        margin-top: 150px;
        z-index: 999;
        .select_barista_text{
            color: #AADBE9;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin-top: 15px;
            width: 100%;
            text-align: center;
            background-color: #353535;
        }

        .select_barista_inner{

            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            border: 2px solid #AADBE9;
            background-color: #353535;
            border-radius: 20px;
            padding: 30px 20px;
            margin-top: 30px;
            >.title{
                font-size: 20px;
                line-height: 20px;
                font-weight: 600;
                margin-bottom: 10px;
                color: #a6a089;
            }
            .barista_selected{
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #AADBE9;
                margin-top: 25px;
                .label{
                    color: #A6A08A;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                }
                .value{
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 600;
                }
            }
            .bt_save_barista{
                display: flex;
                align-self: center;
                font-size: 17px;
                line-height: 17px;
                height: 43px;
                padding-left: 21px;
                padding-right: 21px;
                margin-top: 3 0px;
            }
            .select_barista{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-top: 20px;
                .barista_item{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 20px;
                    :last-child{
                        margin-bottom: 0;
                    }
                    .name{
                        margin-right: 10px;
                        
                        p{
                            font-size: 16px;
                            line-height: 22px;
                            font-weight: 500;
                            margin: 0!important;
                            text-transform: uppercase;
                        }
                    }
                    .actions{
                        .bt_barista_check{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 30px;
                            line-height: 30px;
                            width: 30px;
                            height: 30px;
                            //border: 2px solid #AADBE9;
                            background-color: #FFFFFF;
                            border-radius: 6px;
                            cursor: pointer;
                            color: #AADBE9;
                            &.selected{
                                background-color: transparent;
                                border-color: transparent;
                            }
                            :hover{
                                //border: 2px solid #a6a089;
                            }
                            &.selected{
                                :hover{
                                    border-color: transparent;
                                    cursor: default;
                                }
                            }
                        }
                    }
                }
            }
            .select_boca{
                display: flex;
                justify-content: center;
                width: 100%;
                select{
                    width: 100%;
                    font-size: 18px;
                    color: #FFFFFF;
                    background-color: #a6a089;
                    border: 2px solid #AADBE9;
                    padding: 6px 10px;
                    outline: 0;
                    border-radius: 6px;
                }
            }
        }
    }
    .bt_gray{
        display: flex;
        justify-content: center;
        align-items: center;
        //border: 2px solid #AADBE9;
        background-color: #B5AF99;
        color: #FFFFFF;
        border-radius: 10px;
        font-size: 23px;
        line-height: 23px;
        font-weight: 500;
        padding-left: 25px;
        padding-right: 25px;
        height: 55px;
        cursor: pointer;
        p{
            margin: 0;
            text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
        }

        :hover{
            //border: 2px solid #B5AF99;
            background-color: #AADBE9;
            color: #3C3C3B;
            p{
                margin: 0;
                text-shadow: 0px 0px 0px rgba(0,0,0,0.6);
            }
        }
    }
    .bt_skyblue{
        display: flex;
        justify-content: center;
        align-items: center;
        //border: 2px solid #B5AF99;
        background-color: #AADBE9;
        color: #FFFFFF;
        border-radius: 10px;
        font-size: 30px;
        line-height: 30px;
        font-weight: 500;
        padding-left: 30px;
        padding-right: 30px;
        height: 61px;
        cursor: pointer;
        p{
            margin: 0;
            text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
        }

        :hover{
            //border: 2px solid #AADBE9;
            background-color: #B5AF99;
            color: #3C3C3B;
            p{
                margin: 0;
                text-shadow: 0px 0px 0px rgba(0,0,0,0.6);
            }
        }
    }
    @media ${breakpoints.xs} {
        .box_welcome{
            .welcome_video{
                width: 540px;
                height: 306px;
            }
            .welcome_text{
                width: 540px;
                font-size: 16px;
                line-height: 22px;
            }
        }

        .box_voting,
        .box_select_barista,
        .box_upload_video_etapa1,
        .box_voting_pending,
        .box_approve_video{
            >.actions{
                flex-direction: row;
                .bt_instructivo,
                .bt_reglamento,
                .bt_faq{
                    font-size: 15px;
                    line-height: 15px;
                    padding-left: 20px;
                    padding-right: 20px;
                    height: 41px;
                }
                .bt_faq{
                    margin-left: 10px;
                    margin-top: 0;
                }
                .bt_reglamento{
                    margin-left: 10px;
                    margin-top: 0;
                }
            }
        }
        .box_voting{
            .inner{
                .box_video_list{
                    .video_elements{
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 20px;
                        align-items: start;
                        .video_element{
                            .video{
                                width: 48vw;
                                height: 27vw;
                                //width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .box_approve_video{
            .inner{
                .box_video_list{
                    .video_elements{
                        grid-template-columns: repeat(2, 1fr);
                        .video_element{
                            .video{
                                width: 48vw;
                                height: 27vw;
                            }
                        }
                    }
                }
            }
        }
        .box_upload_video_etapa1{
            .box_video_guide_etapa1{
                width: 88.5vw;
                height: 50vw;
            }
        }
    }
    @media ${breakpoints.sm} {
        
        .box_upload_video_etapa1,
        .box_voting_pending{
            width: 700px;
        }
        .baristas_lineas{
            width: 450px;
            display: flex;
            position: absolute;
            left: 0;
            top: 0;
        }
        .baristas_lineas_r{
            width: 450px;
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
        }
        .box_welcome{
            .welcome_video{
                width: 600px;
                height: 339px;
            }
            .welcome_text{
                width: 730px;
            }
        }
        
        .box_voting,
        .box_select_barista,
        .box_upload_video_etapa1,
        .box_voting_pending,
        .box_approve_video{

            >.actions{
                flex-direction: row;
                .bt_instructivo,
                .bt_reglamento,
                .bt_faq{
                    font-size: 18px;
                    line-height: 18px;
                    padding-left: 20px;
                    padding-right: 20px;
                    height: 45px;
                    /*
                    font-size: 20px;
                    line-height: 20px;
                    padding-left: 25px;
                    padding-right: 25px;
                    height: 51px;
                    */
                }
                .bt_faq{
                    margin-left: 10px;
                    margin-top: 0;
                }
                .bt_reglamento{
                    margin-left: 10px;
                    margin-top: 0;
                }
            }

        }
        .box_upload_video_etapa1{
            .box_video_guide_etapa1{
                width: 646px;
                height: 364px;
            }
        }
        
    }
    @media ${breakpoints.md} {
        .box_upload_video_etapa1,
        .box_voting_pending{
            width: 800px;
        }
        .box_select_barista{
            width: 800px;
            .select_barista_text{
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                margin-top: 15px;
                width: 800px;
            }
            .select_barista_inner{
                .select_boca{
                    select{
                        width: 500px;
                        font-size: 18px;
                        color: #FFFFFF;
                        background-color: #a6a089;
                        border: 2px solid #AADBE9;
                        padding: 6px 10px;
                        outline: 0;
                        border-radius: 6px;
                    }
                }
            }
        }
        .box_welcome{
            .welcome_video{
                width: 650px;
                height: 368px;
            }
            .welcome_text{
                width: 800px;
            }
        }

        .box_upload_video_etapa1{
            .box_video_guide_etapa1{
                width: 744px;
                height: 419px;
            }
        }

    }
    @media ${breakpoints.lg} {

        .box_voting{
            margin-top: 150px;

            display: flex;
            flex-direction: column;
            align-items: center;
            width: 1160px;//calc(100% - 30px);
            margin-top: 150px;
            z-index: 999;
            .inner{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin-top: 30px;
                .intro_text{
                    width: 800px;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    margin-bottom: 20px;
                    color: #AADBE9;
                }
                .box_video_list{
                    .video_elements{
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        grid-gap: 20px;
                        align-items: start;
                        .video_element{
                            .video{
                                width: 100%;
                                height: 210px;
                            }
                            /*
                            .video{
                                width: 100%;
                                
                                //width: 386px;
                                //height: 300px;
                            }
                            */
                        }
                    }
                }
            }
        }

        .box_approve_video{
            width: 1160px;
            .inner{
                .intro_text{
                    width: 800px;
                }
                .box_video_list{
                    .video_elements{
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        .video_element{
                            
                            .video{
                                width: 100%;
                                height: 210px;
                                //width: 386px;
                                //height: 300px;
                            }
                        }
                    }
                }
            }
        }

    }
`