import React, { useContext, useEffect, useState } from 'react';
import storage from 'local-storage-fallback';
import styled from 'styled-components';
import { Header, Sidebar } from '../_components';
import { AuthContext, ContentContext } from '../_context';
import { viewedVideoLanding } from '../_helpers/constants';
import { breakpoints } from '../_helpers/breakpoints';
import RegisterPhone from '~/_components/Layout/RegisterPhone';
import CountdownVideoLanding from '~/_components/Layout/CountdownVideoLanding';
import useMediaQuery from "../_hooks/useMediaQuery";
import { useHistory } from 'react-router';

//import Evaluation from '~/_components/Evaluation';


const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  min-height: 100%;
  
  background-color: ${props => props.background.type === 'color' ? props.background.hexa : 'transparent'}!important;
  
  background-size: ${props => props.background.type === 'image' ? props.background.size : 'unset'};
  background-repeat: ${props => props.background.type === 'image' ? props.background.repeat : 'unset'};
  background-image: ${props => props.background.type === 'image' ? "url('"+props.background.url+"')" : 'unset'};
  background-attachment: ${props => props.background.type === 'image' ? props.background.attachment : 'unset'};
  
  >.inner{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

`
const WrapperBaristas = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: flex-start!important;
  align-items: flex-start!important;
  width: 100%;
  background-color: #353535; */

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  min-height: 100%;
  
  background-color: #353535!important;
  background-repeat: repeat;
  background-image: url("/images/baristas_backdots.svg");
  /* background-size: cover; */
  
  /*
  background-size: ${props => props.background.type === 'image' ? props.background.size : 'unset'};
  background-repeat: ${props => props.background.type === 'image' ? props.background.repeat : 'unset'};
  background-image: ${props => props.background.type === 'image' ? "url('"+props.background.url+"')" : 'unset'};
  background-attachment: ${props => props.background.type === 'image' ? props.background.attachment : 'unset'};
  */
  >.inner{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

  
`
const WrapperLogin = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

`
const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;//768px;//calc(100% - 30px);
  //padding-top: 2vw;
  //padding-bottom: 10vw;

  position: relative;
  margin-top: 90px;
  z-index: 6;

  //background-color: gray;
  @media ${breakpoints.xs} {
    //padding-top: 10px;
    //padding-bottom: 50px;
    //width: 576px;
  }
  @media ${breakpoints.sm} {
    //width: 768px;
  }
  @media ${breakpoints.md} {
    //width: 951px;
  }
  @media ${breakpoints.lg} {
    //width: 1160px;
  }
`

const Layout = ({children}) => {

  //const { history, location } = useHistory();
  const history = useHistory();

  const isSm = useMediaQuery('(min-width: 768px)');
  //showMoreStats, setShowMoreStats,

  const { isAuth } = useContext(AuthContext);
  const { userData,
          setShowMoreStats,
          colorPallete,
          setColorPallete,
          setVideoViewed,
          getInternalCourses,
          evaluationData,
          setEvaluationData } = useContext(ContentContext);

  const [ background, setBackground ] = useState({default: true, type: 'color', hexa: '#FFFFFF'});
  const [ sectionName, setSectionName ] = useState(null);


  useEffect(() => {
    setShowMoreStats(isSm);
    return () => {}
  }, [isSm])

  useEffect(() => {

    const section_name = history.location.pathname.split("/")[1];
    setSectionName(section_name);

    switch (section_name) {
      case 'trivia':

        /*
        setBackground({default: true, type: 'color', hexa: '#FFFFFF'});
        setColorPallete({type: 'default', primary: '#000000', secondary: '#FFFFFF', terciary: '#F5F6FA', border: '204, 204, 204', background: '#FFFFFF', altbackground: 'rgba(0, 0, 0, 0.5)'});
        */

        break;
      case 'shellbox':

        /*
        setBackground({default: false,
                       type: 'image',
                       url: '/images/background_latambox1.svg',
                       repeat: 'repeat-y',
                       attachment: 'fixed',
                       size: 'cover'});
                       
        setColorPallete({type: 'shellbox', primary: '#000000', secondary: '#FFFFFF', terciary: '#F5F6FA', border: '255, 255, 255', background: 'rgba(255, 255, 255, 0.30)', altbackground: 'rgba(255, 255, 255, 1)'});
        */

        break;
      case 'qatar2022':

        /*
        setBackground({default: false,
                       type: 'image',
                       url: '/images/background_qatar2022_1.svg',
                       repeat: 'repeat-y',
                       attachment: 'fixed',
                       size: 'cover'});

        setColorPallete({type: 'shellbox', primary: '#FFFFFF', secondary: '#FFFFFF', terciary: '#F5F6FA', border: '255, 255, 255', background: 'rgba(255, 255, 255, 0.30)', altbackground: 'rgba(255, 255, 255, 1)'});
        */

        break;
      case 'challengebox':

        /*
        setBackground({default: false,
                       type: 'image',
                       url: '/images/fondo-amarillo1.jpg',
                       //url: '/images/background_challengebox.jpg',
                       repeat: 'repeat',
                       attachment: 'fixed',
                       size: 'contain'});

        setColorPallete({type: 'shellbox', primary: '#000000', secondary: '#FFFFFF', terciary: '#F5F6FA', border: '255, 255, 255', background: 'rgba(255, 255, 255, 0.30)', altbackground: 'rgba(255, 255, 255, 1)'});
        */

        break;
      case 'semana-de-la-seguridad':

        // setBackground({default: false,
        //                type: 'image',
        //                url: '/images/back_diaseguridad5.jpg',
        //                //url: '/images/background_challengebox.jpg',
        //                repeat: 'repeat',
        //                attachment: 'fixed',
        //                size: 'cover'});

        setBackground({default: true, type: 'color', hexa: '#FFC600'});
        setColorPallete({type: 'shellbox', primary: '#FFD200', secondary: '#FFFFFF', terciary: '#F5F6FA', border: '255, 210, 0', background: 'rgba(0, 0, 0, 0.5)', altbackground: 'rgba(0, 0, 0, 0.5)'});

        break;
      case 'incentivo-tiendas':

        setBackground({default: false,
                       type: 'image',
                       url: '/images/back_incentivotiendas.jpg',
                       //url: '/images/background_challengebox.jpg',
                       repeat: 'repeat',
                       attachment: 'fixed',
                       size: 'cover'});

        //setBackground({default: true, type: 'color', hexa: '#FFC600'});
        setColorPallete({type: 'shellbox', primary: '#FFD200', secondary: '#FFFFFF', terciary: '#F5F6FA', border: '255, 210, 0', background: 'rgba(0, 0, 0, 0.5)', altbackground: 'rgba(0, 0, 0, 0.5)'});

        break;
      default:

        /*
        if(section_name !== 'shellbox'){
          if(!background.default){
            setBackground({default: true, type: 'color', hexa: '#FFFFFF'});
          }
        }
        */

        
        setBackground({default: true, type: 'color', hexa: '#FFFFFF'});
        setColorPallete({type: 'default', primary: '#000000', secondary: '#FFFFFF', terciary: '#F5F6FA', border: '204, 204, 204', background: '#FFFFFF', altbackground: 'rgba(204, 204, 204, 0.4)'});
        

        break;
    }

    if(isSm){
      setShowMoreStats(true);
    }else{
      setShowMoreStats(false);
    }
    return () => {}
  }, [history.location])

  /*
  let isMobileApp = false;
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get('utm_source') === 'mobile_app') {
    isMobileApp = true;
  }
  */

  useEffect(() => {
    console.log("history.location.pathname", history.location.pathname == "/baristas")
    return () => {

    }
  }, [])

  //if (user.isAuth === false || Object.keys(protagonista).length === 0 || Object.keys(notificaciones).length === 0) {
  if (isAuth === false) {
    return <WrapperLogin>{children}</WrapperLogin>;
  }

  if(history.location.pathname == "/baristas"){
    return (
      <WrapperBaristas className='wrapper_baristas' breakpoints={breakpoints} background={background}>
        <div className='inner'>{children}</div>
      </WrapperBaristas>
    )
  }

  return (
    <WrapperStyled breakpoints={breakpoints} background={background}>
      <Header />
      <div className='inner'>
        <Sidebar />
        <Content className='box_content'>
          {children}
        </Content>
      </div>
      <RegisterPhone />
    </WrapperStyled>
  )

}

export default Layout;
