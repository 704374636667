import React, { useState, useEffect, useContext } from 'react';
import { breakpoints } from '../../_helpers/breakpoints';
import styled from 'styled-components';
import moment from 'moment';
import { RiCloseCircleLine } from "react-icons/ri";
import CircularProgress from '@material-ui/core/CircularProgress';
import { path } from 'ramda';
import InfiniteScroll from 'react-infinite-scroll-component';
import Post from './Post';
import CommentsDrawer from './CommentsDrawer';
import ModalPostMedia from './ModalPostMedia';
import Drawer from '@mui/material/Drawer';

import { } from '../../_elements';

import { ContentContext } from '../../_context';

const WrapperStyled = styled.div`
  width: 100%;
  //background-color: pink;
  p.no_more_posts{
    margin-top: 40px;
    font-weight: 600;
  }
  .loader_wall{
    padding: 20px;
    svg{
      color: #FFC600;
    }
  }
  .filter_wall{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
    >.title{
      margin-right: 10px;
      margin-bottom: 5px;
      white-space: nowrap;
    }
    ul.filter_tags{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 0;
      li.tag{
        font-size: 15px;
        line-height: 15px;
        font-weight: 500;
        white-space: nowrap;
        border: 1px solid black;
        margin: 0;
        padding: 0;
        margin-right: 10px;
        margin-bottom: 5px;
        border-radius: 6px;
        box-sizing: border-box;
        padding: 5px 10px;
        cursor: pointer;
        :last-child{
          margin-right: 0;
        }
        &.selected{
          background-color: #FFC600;
          border: 1px solid #FFC600;
          color: #FFFFFF;
        }
        :hover{
          background-color: #FFC600;
          border: 1px solid #FFC600;
          color: #FFFFFF;
        }
      }
    }
  }
  .posts{

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    //padding-left: 7px;

    .post{
      width: 87vw;
      margin-top: 3vw;
    }
  }

  @media ${breakpoints.xs} {//576px
    width: 576px;
    .posts{
      display: flex;
      flex-direction: row;
      width: 576px;
      padding-left: 0;
      .post{
        width: 546px;
        margin: 15px 15px;
      }
    }
  }
  @media ${breakpoints.sm} {//768px
    width: 748px;
    .filter_wall{
      flex-direction: row;
      margin-top: 10px;
      margin-bottom: 15px;
      margin-left: 0;
      margin-right: 15px;
      >.title{
        margin-bottom: 0;
      }
    }
    .posts{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      width: 748px;
      .post{
        width: 363px;
        margin: 0;
      }
    }
  }
  @media ${breakpoints.md} {//992px
    width: 800px;
    .posts{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      width: 100%;
      .post{
        width: 390px;
        margin: 0;
      }
    }
  }
  @media ${breakpoints.lg} {//1200px
    width: 1000px;
    .posts{
      width: 100%;
      .post{
        width: 490px;
        margin: 0;
      }
    }
  }
  @media ${breakpoints.xl} {//1366px
    width: 1200px;
    .posts{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      width: 100%;
      .post{
        width: 385px;
        margin: 0;
      }
    }
  }
  @media ${breakpoints.xxl} {//1440px
    width: 1280px;
    .posts{

      .post{
        width: 410px;
        margin: 0;
      }
    }
  }
  @media ${breakpoints.xxxl} {//1680px
    width: 1500px;
    .posts{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      width: 100%;
      .post{
        width: 485px;
        margin: 0;
      }
    }
  }
  @media ${breakpoints.xxxxl} {//1920px
    width: 1750px;
    .posts{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;
      width: 100%;
      .post{
        width: 420px;
      }
    }
  }

`

const DrawerStyled = styled(Drawer)`
  .MuiDrawer-paper{
    //position: relative;
    background-color: #F8F9FF;
    width: 100%;
    .inner_drawer{
      width: 100%;
      .comments_drawer{
        width: 100%;
        background-color: #F8F9FF;
      }
    }
  }

  @media ${breakpoints.xs} {//576px
    .MuiDrawer-paper{
      width: auto;
      .inner_drawer{
        width: 500px;
      }
    }
  }
  @media ${breakpoints.sm} {//768px
    .MuiDrawer-paper{
      width: auto;
      .inner_drawer{
       width: 600px;
      }
    }
  }
  @media ${breakpoints.md} {//992px

  }
  @media ${breakpoints.lg} {//1200px

  }
  @media ${breakpoints.xl} {//1366px

  }
  @media ${breakpoints.xxl} {//1440px

  }
  @media ${breakpoints.xxxl} {//1680px

  }
  @media ${breakpoints.xxxxl} {//1920px

  }
`
const LoaderWallStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
    svg{
      color: #FFC600;
    }
`

const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #CCCCCC!important;
  }
`


const WallContainer = (props) => {

  const {  } = props

  const { get_wall, 
          get_reactions_wall,
          get_reactions_wall_according_id,
          get_post_by_id,
          notificationPostSelected,
          setNotificationPostSelected } = useContext(ContentContext);
           
  const [ posts, setPosts ] = useState([]);
  const [ tags, setTags ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ page, setPage ] = useState(0);
  const [ activeReactions, setActiveReactions ] = useState(1);
  const [ showComments, setShowComments ] = useState(false);
  const [ postSelected, setPostSelected ] = useState(null);
  const [ filterByTag, setFilterByTag ] = useState(null);
  const [ postMediaSelected, setPostMediaSelected ] = useState(null);
  const [ postsTotal, setPostsTotal ] = useState(0);
  const [ postsCommentsReactions, setPostsCommentsReactions ] = useState([]);
  const [ idsToCounterGet, setIdsToCounterGet ] = useState(null);
  const [ partPostLoaded, setPartPostLoaded ] = useState(null);

  const [ postReactions, setPostReactions ] = useState([]);
  
  useEffect(() => {
    console.log("posts.length", posts.length)
    if(!posts.length){
      getWall();
    }
    //get_reactions_wall_according_id("");
    return () => {}
  }, [posts])

  const getWall = (force_page = null) => {

    setIsLoading(true);
    setError(null);
    const payload = {page: force_page !== null ? force_page : page, size: 13, filter: filterByTag ? filterByTag : ""}//30
    const result = get_wall(payload);
    result.then(res => {

      if(!res){
        return null;
      }

      const post_res = path(['data', 'single', 'data'], res);
      setPartPostLoaded(post_res);

      if(!posts.length){
        let reactions = path(['data', 'single', 'reactions'], res);
        const reaction_like = { "name": "like", "index": 0, "_id": "" }
        setActiveReactions(reactions/*[reaction_like, ...reactions]*/);
      }

      setPage(prevPage => prevPage + 1);
      setPosts(prevItems => [...prevItems, ...post_res]);//path(['data', 'single', 'data'], res)
      setPostsTotal(path(['data', 'single', 'total'], res));
      
      let array_tags = path(['data', 'single', 'tags'], res);

      /*
      let ids_to_counter_get = [];
      let postsCommentsReactions_update = [...postsCommentsReactions];
      post_res.map(p => {
        let exist = postsCommentsReactions_update.filter(post_comment => post_comment._id === p._id);
        if(!exist.length){
          ids_to_counter_get.push(p._id);
          postsCommentsReactions_update.push({_id: p._id, comments: "", reactions: ""})
        }
        return p;
      })
      setPostsCommentsReactions(postsCommentsReactions_update);
      setIdsToCounterGet(ids_to_counter_get);

      console.log("* * * * * * * * * * * * * * * * * * * * * * * * *")
      console.log("ids_to_counter_get", ids_to_counter_get);
      console.log("* * * * * * * * * * * * * * * * * * * * * * * * *")
      handleGetReactionsAndComments(ids_to_counter_get);
      */

      setTags(array_tags);
      // setTags(array_tags ? array_tags.reverse() : []);

      setIsLoading(false);
      setError(null);

    })

  }

  const handleGetReactionsAndComments = (ids_to_counter_get) => {

    

    const postsCommentsReactionsUpdate = [...postsCommentsReactions];

    for (let index = 0; index < ids_to_counter_get.length; index++) {
      const payload = {post_id: ids_to_counter_get[index]};
      const response = get_reactions_wall(payload);
      response.then(res => {

        postsCommentsReactionsUpdate.forEach(post => {
          //console.log("post", post)
          post.reactions = res.data.single;
        });
        //console.log("get_reactions_wall", res);
      })
    }
    
    //console.log("postsCommentsReactionsUpdate", postsCommentsReactionsUpdate, postsCommentsReactions)

    setPostsCommentsReactions(postsCommentsReactionsUpdate);

  }

  useEffect(() => {
    
    if(partPostLoaded){
      let arr_ids = [];
      
      //console.log("-> partPostLoaded", partPostLoaded)
      //let postsCommentsReactions_update = [...postsCommentsReactions];
      partPostLoaded.forEach(post => {
        //console.log("post", post)
        arr_ids.push({_id: post._id});
      });
      
      if(arr_ids.length){
        const response = get_reactions_wall_according_id(arr_ids);
        response.then(res => {
          setPostsCommentsReactions(prevItems => [...prevItems, ...res.data.single.posts_reactions]);
        })
      }
      

      //console.log("arr_ids", arr_ids);
      //
      /*
      let ids_to_counter_get = [];
      let postsCommentsReactions_update = [...postsCommentsReactions];
      post_res.map(p => {
        let exist = postsCommentsReactions_update.filter(post_comment => post_comment._id === p._id);
        if(!exist.length){
          ids_to_counter_get.push(p._id);
          postsCommentsReactions_update.push({_id: p._id, comments: "", reactions: ""})
        }
        return p;
      })
      setPostsCommentsReactions(postsCommentsReactions_update);
      setIdsToCounterGet(ids_to_counter_get);
      */

      //handleGetReactionsAndComments(partPostLoaded);
    }
  
    return () => {}
  }, [partPostLoaded])
  
  /*
  useEffect(() => {
    if(idsToCounterGet){
      if(postsCommentsReactions){
        console.log("- - - - - - - - - - - - - - - - - - - - -")
        console.log("idsToCounterGet", idsToCounterGet, postsCommentsReactions);
        console.log("- - - - - - - - - - - - - - - - - - - - -")
        handleGetReactionsAndComments(idsToCounterGet);
        setIdsToCounterGet(null);
      }
    }
    return () => {}
  }, [idsToCounterGet, postsCommentsReactions])
  
  
  useEffect(() => {
    console.log("- - - - - - - - - - - - - - - - - - - - -")
    console.log("postsCommentsReactions", postsCommentsReactions.length, postsCommentsReactions);
    console.log("- - - - - - - - - - - - - - - - - - - - -")
  
    return () => {}
  }, [postsCommentsReactions])
  */
 
  const handleShowComments = () => {
    setShowComments(!showComments);
  }

  const handleModalPostMedia = (post) => {
    setPostMediaSelected(post);
  }

  const handleFilterWall = (isFiltering, tag_id) => {
    if(isFiltering){
      setFilterByTag(tag_id);
    }else{
      setFilterByTag("");
    }
  }

  const handleGetCommentsCounter = (_id) => {

    const response = get_reactions_wall_according_id([{_id: _id}]);
    response.then(res => {
      console.log("res", res)

      let postsCommentsReactionsUpdate = postsCommentsReactions.map(post => {
        console.log("---->", post)
        if(post.post_id === _id){
          post.comment_count = res.data.single.posts_reactions[0].comment_count;
        }
        return post;
      })
      setPostsCommentsReactions(postsCommentsReactionsUpdate);
      /*
      postsCommentsReactions.forEach(post => {
        if(post._id === _id){

        }
      });
      setPostsCommentsReactions(prevItems => [...prevItems, ...res.data.single.posts_reactions]);
      */

      //
      //
      //setCommentCounter(postExist[0].comment_count)
    })
  }


  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = event => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    if(filterByTag !== null){
      setPage(0);
      setPosts([])
      getWall(0);
      if(!filterByTag.length){
        setFilterByTag(null);
      }
    }

    return () => {};
  }, [filterByTag]);

  useEffect(() => {
    if(notificationPostSelected){
      setPostSelected({});
      handleShowComments();
      const response = get_post_by_id({post_id: notificationPostSelected});
      response.then(res => {
        console.log("res", res.data.single.data)
        setPostSelected(res.data.single.data);
        handleGetCommentsCounter(notificationPostSelected);
        setNotificationPostSelected(null);
      })
    }
    return () => {}
  }, [notificationPostSelected])


  if(!posts || postsTotal <= 0){
    return <LoaderWallStyled><CircularProgress /></LoaderWallStyled>;
  }

  return (
    <WrapperStyled>

      {postMediaSelected &&
      <ModalPostMedia
        postMediaSelected={postMediaSelected}
        setPostMediaSelected={setPostMediaSelected}
      />}

      {postSelected &&
      <DrawerStyled
        anchor={'right'}
        open={showComments}
        onClose={handleShowComments}
      >
        <div className='inner_drawer'>
          <Post
            data={postSelected}
            activeReactions={activeReactions}
            className={"post"}
            handleModalPostMedia={handleModalPostMedia}
            handleShowComments={handleShowComments}
            postSelected={postSelected}
            setPostSelected={setPostSelected}
            scope={"drawer"}
            posts={posts}
            setPosts={setPosts}
            postsCommentsReactions={postsCommentsReactions}
            setPostsCommentsReactions={setPostsCommentsReactions}
            handleGetCommentsCounter={handleGetCommentsCounter}
          />

          <CommentsDrawer
            className="comments_drawer"
            data={postSelected}
            activeReactions={activeReactions}
            posts={posts}
            setPosts={setPosts}
            handleGetCommentsCounter={handleGetCommentsCounter}
          />
        </div>

      </DrawerStyled>
      }

      <div className='filter_wall'>
        <div className='title'>Filtrar por:</div>
        <ul className='filter_tags'>
          <li className={`tag${(filterByTag === null || filterByTag === '') ? ' selected' : ''}`} onClick={() => handleFilterWall(false, "")}>Todo</li>
          {tags ?
            tags.map((tag, index) => {
              return(
                <li className={`tag${tag._id === filterByTag ? ' selected' : ''}`} onClick={() => handleFilterWall(true, tag._id)}>
                  { tag.name }
                </li>
              )
            })
            :
            <>cargandoa</>
          }
        </ul>
      </div>
      <InfiniteScroll
        className='infinite_scroll'
        dataLength={posts.length}
        next={getWall}
        hasMore={posts.length < postsTotal ? true : false} // Replace with a condition based on your data source
        loader={<div className='loader_wall'><CircularProgress /></div>}
        endMessage={<p className='no_more_posts'>No hay más posteos para mostrar.</p>}
      >
        <div className='posts'>
          {posts ?
            posts.map((post, index) => {
              return(
                <Post
                  data={post}
                  activeReactions={activeReactions}
                  className={`post ${index % 4 == 0? "startline" : ""}`}
                  handleModalPostMedia={handleModalPostMedia}
                  handleShowComments={handleShowComments}
                  postSelected={postSelected}
                  setPostSelected={setPostSelected}
                  setPostMediaSelected={setPostMediaSelected}
                  scope={"feed"}
                  posts={posts}
                  setPosts={setPosts}
                  postsCommentsReactions={postsCommentsReactions}
                  setPostsCommentsReactions={setPostsCommentsReactions}
                />
              )
            })
            :
            <>cargando</>
          }
        </div>
      </InfiniteScroll>
    </WrapperStyled>
  )
}

export default WallContainer;
