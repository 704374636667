import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';
import styled from 'styled-components';
import { ContentContext } from '../../_context';
import { path } from 'ramda';
import { SideLeft, SideRight, DashboardStats, CatalogueItem } from '../../_components';
import { breakpoints } from '../../_helpers/breakpoints';
import { Modal, Fade, Backdrop, Box } from '@material-ui/core';
import { RiCloseCircleLine } from "react-icons/ri";
import { Spinner, COLORS } from '../../_helpers/constants';
import LazyLoad from 'react-lazyload';
import CircularProgress from '@material-ui/core/CircularProgress';
import Breadcrumb from '../../_components/Catalogue/Breadcrumb';
import { HiDownload } from "react-icons/hi";

const Wrapper = styled.div`

  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  //padding-top: 15px;
  padding-bottom: 15px;
  .product_detail{
    box-sizing: border-box;
    padding: 15px 20px;
    .product_detail_header{
      display: flex;
      flex-direction: column;
      align-items: center;
      .image{
        width: 300px;
        img{
          float: left;
          width: 100%;
        }
      }
      .detail_data{
        display: flex;
        flex-direction: column;
        align-items: center;
        .title{
          font-size: 6vw;
          line-height: 7vw;
          font-weight: 600;
          margin-top: 6vw;
        }
        .category{
          font-size: 3.3vw;
          line-height: 4.3vw;
          font-weight: 600;
          color: #D8010F;
          margin-top: 1vw;
        }
      }
    }
    .product_detail_information{
      margin-top: 35px;
      .information{
        margin-bottom: 25px;
        .title{
          font-size: 19px;
          line-height: 19px;
          font-weight: 600;
        }
        .description{
          font-size: 17px;
          line-height: 23px;
          white-space: pre-line;
          margin-top: 10px;
        }
        .archives{
          display: flex;
          flex-direction: row;
          margin-top: 10px;
          .archive{
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #FFC600;
            border: 1px solid #FFC600;
            border-radius: 6px;
            color: #000000;
            box-sizing: border-box;
            padding: 13px 23px 13px 19px;
            margin-right: 5px;
            cursor: pointer;
            .icon{
              font-size: 23px;
              line-height: 23px;
              margin-right: 10px;
            }
            .name{
              font-size: 16px;
              line-height: 20px;
              font-weight: 600;
            }
            :hover{
              background-color: #FFFFFF;
              color: #FFC600;
            }
          }
        }
      }
    }
  }
  @media ${breakpoints.xs} {



  }
  @media ${breakpoints.sm} {

    .product_detail{
      padding: 15px 20px;
      .product_detail_header{
        display: flex;
        flex-direction: row;
        align-items: center;
        .image{
          width: 300px;
        }
        .detail_data{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .title{
            font-size: 35px;
            line-height: 40px;
            margin-top: 0;
          }
          .category{
            font-size: 18px;
            line-height: 23px;
            font-weight: 600;
            margin-top: 5px;
          }
        }
      }
      .product_detail_information{
        margin-top: 35px;
        .information{
          margin-bottom: 25px;
          .title{
            font-size: 19px;
            line-height: 19px;
          }
          .description{
            font-size: 17px;
            line-height: 23px;
            margin-top: 10px;
          }
          .archives{
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            .archive{
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 13px 23px 13px 19px;
              margin-right: 5px;
              cursor: pointer;
              .icon{
                font-size: 23px;
                line-height: 23px;
                margin-right: 10px;
              }
              .name{
                font-size: 16px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }

  }
  @media ${breakpoints.md} {
  }
  @media ${breakpoints.lg} {
  }
  @media ${breakpoints.xxl} {
  }
  @media ${breakpoints.xxxl} {
  }

`

const CircularProgressStyled = styled(CircularProgress)`
  svg{
    color: #CCCCCC!important;
  }
`

const ProductContainer = (props) => {

  const { categoriesBrand,
          handleSubcategories,
          handleProducts } = props;

  const { product,
          setProduct,
          categoriesSelected,
          setCategoriesSelected,
          setCategoriesBrand,
          setProducts } = useContext(ContentContext);

  const [ crumbs, setCrumbs ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    //console.log("categoriesSelected", categoriesSelected);
    const create_crumb = [];
    create_crumb.push({_id: null, label: "Catálogo", type: "section", level: null});
    categoriesSelected.map((c, index) => {
      create_crumb.push({_id: c._id, label: c.name, type: index === 0 ? "category" : "subcategory", level: c.level});
    })
    create_crumb.push({_id: product._id, label: product.name, type: "product", level: categoriesSelected.length});
    setCrumbs(create_crumb);

    return () => {}
  }, [])

  const handleGoToCustom = (el) => {
    //console.log(el)

    //console.log(el.type)

    if(el.type !== 'product'){
      setProduct(null);

      // console.log(el._id);
      // console.log(el.level);
      // console.log(categoriesSelected);
      // console.log(categoriesSelected.filter((c => c.level === el.level)));

      let index;
      const categoriesSelected_update = [];

      categoriesSelected.map((c, i) => {
        if(c.level === el.level){
          index = i;
        }
      })

      for(let n=0; n<=index; n++){
        categoriesSelected_update.push(categoriesSelected[n]);
      }

      setCategoriesSelected(categoriesSelected_update);

      if(el.level === null){
        setCategoriesBrand(null);
        setProducts(null);
        //setProducts, setCategoriesBrand, setProduct, setCategoriesSelected
      }

      if(el.level === 0){
        setProducts(null);
      }

    }else{

    }

  }

  const handleArchiveLink = (media_url) => {
    window.open(media_url, '_blank').focus();
  }

  return (
    <Wrapper>

      <Breadcrumb
        crumbs={crumbs}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        socpe={"products"}
        handleGoToCustom={handleGoToCustom}
        {...props}
      />

      <div className='product_detail'>

        <div className='product_detail_header'>
          {product.medias.length ?
          <div className='image'>
            <img src={`${product.medias[0].media_url}`} alt="" />
          </div> : "" }
          <div className='detail_data'>
            <div className='title'>{ product.name }</div>
            <div className="category">{ crumbs.length ? crumbs[crumbs.length-2].label.length ? crumbs[crumbs.length-2].label : crumbs[1].label : '' }</div>
          </div>
        </div>

        <div className='product_detail_information'>
          <div className='information'>
            <div className='title'>
              {product.name}
            </div>
            <div className='description'>
              {product.description_short}
            </div>
          </div>

          <div className='information'>
            {/* <div className='title'>
              {info.label}
            </div> */}
            {product?.medias.length ?
              <div className='archives'>
                {product?.medias.map((file, index) => {
                  return(
                    <div className='archive' onClick={() => handleArchiveLink(file.media_url)}>
                      <div className='icon'><HiDownload /></div>
                      <div className='name'>{file.media_name}</div>
                    </div>
                  )
                })}
              </div>
            : "" }
          </div>

                    
          {product?.information ?
            product.information.map((info, index) => {
              return(
                <Fragment>
                  {(info?.label === "Descripción" && info.data[0].description_short.length) ?
                    <div className='information'>
                      <div className='title'>
                        {info.label}
                      </div>
                      {info.data.length ?
                      <div className='description'>
                        {info.data[0].description_short}
                      </div> : ""}
                    </div>
                    : ""
                  }
                  {(info?.label === "Archivos" && info.data.length) ?
                    <div className='information'>
                      <div className='title'>
                        {info.label}
                      </div>
                      {info.data.length ?
                        <div className='archives'>
                          {info.data.map((file, index) => {
                            return(
                              <div className='archive' onClick={() => handleArchiveLink(file.media_url)}>
                                <div className='icon'><HiDownload /></div>
                                <div className='name'>{file.media_name}</div>
                              </div>
                            )
                          })}
                        </div>
                      : "" }
                    </div>
                    : ""
                  }
                </Fragment>
              )
            })
          : ""
          }
        </div>


        {/* {product.medias.length ?
        <div className='image'>
          <img src={`${product.medias[0].media_url}`} alt="" />
        </div>
        :
        <div className=''>{product.name}</div>
        } */}
      </div>


    </Wrapper>
  )
}

export default ProductContainer;
