import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import storage from 'local-storage-fallback';
import { ButtonHeader, ImageRound, ButtonActionRounded, ButtonYellow} from '../../_elements';
//import Encuesta from '../../_components/Dashboard/Encuesta';

import { COLORS } from '../../_helpers/constants';
import { useHistory,
         useLocation } from 'react-router';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import CircularProgress from '@material-ui/core/CircularProgress';
import { breakpoints } from '../../_helpers/breakpoints';
import { GoMegaphone } from "react-icons/go";
import { BiBookBookmark, BiExit, BiMessageDetail, BiEnvelope, BiEnvelopeOpen } from 'react-icons/bi';
import { IoBasketOutline, IoMenu, IoSettingsOutline, IoExtensionPuzzleOutline } from "react-icons/io5";
import { BsBox } from "react-icons/bs";

import { IoMdNotificationsOutline, } from "react-icons/io";
import { AiOutlineMessage, AiFillStar } from "react-icons/ai";
import { RiCloseCircleLine } from "react-icons/ri";
import { GiAirplaneDeparture } from "react-icons/gi";

import { AuthContext } from '../../_context';
import { ContentContext } from '../../_context';
import Goto from '../Wall/Goto';
import { RxExternalLink } from "react-icons/rx";

import { Modal,
         Fade,
         Backdrop } from '@material-ui/core';

import { downloadFileFromUrl } from '../../_helpers/commons';

import ReactTooltip from 'react-tooltip';

const Wrapper = styled.div`

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 79px;
    border-bottom: 1px solid #cccccc;
    padding-left: 20px;
    padding-right: 20px;
    background-color: ${COLORS.secondary};
    position: fixed;
    top: 0;
    z-index: 10;

    .react_tooltip{
      padding: 5px 10px 5px 10px;
    }
    .sideright{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .button_contact{
        //margin-right: 10px;
      }
      .button_logout{

      }
      .button_notifications{
        margin-left: 10px;
        /*
        margin-left: 30px;
        @media ${breakpoints.sm} {
          margin-left: 30px;
        }
        @media ${breakpoints.md} {
          margin-left: 30px;
        }
        @media ${breakpoints.lg} {
          margin-left: 50px;
        }
        */
      }
      .usertag{
        /*
        @media ${breakpoints.sm} {
          margin-left: 45px;
        }
        @media ${breakpoints.md} {
          margin-left: 30px;
        }
        @media ${breakpoints.lg} {
          margin-left: 145px;
        }
        */
      }
    }

`
const MenuStyled = styled.div`
    display: none;
    width: 500px;

    @media ${breakpoints.xs} {
      display: none;
      width: 500px;
    }
    @media ${breakpoints.sm} {
      display: flex;
      width: 400px;
    }
    @media ${breakpoints.md} {
      width: 450px;
    }
    @media ${breakpoints.lg} {
      width: 600px;
    }

`
const UserTag = styled.a`

    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 52px;
    overflow: hidden;
    //width: 54px;
    .title{
      margin-left: 6px;
    }
    .image_round{
        border: 2px solid transparent;
      }
    :hover{
      text-decoration: none!important;
      .image_round{
        border: 2px solid #D8010F;
      }
      .title{
        p{
          color: #D8010F!important;
        }
      }
    }
    &.selected{
      .image_round{
        border: 2px solid #D8010F;
      }
    }

    @media ${breakpoints.sm} {
      .title{
        display: none;
      }
    }
    @media ${breakpoints.md} {
      .title{
        display: flex;
      }
      //width: 180px;
    }
    /*
    @media ${breakpoints.lg} {
      .title{
        display: block;
        margin-left: 7px;
      }
    }
    */


`
/*
const EmbajadorStar = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 999;
  bottom: 0;
  left: 0;
  width: 22px;
  height: 22px;
  font-size: 15px;
  line-height: 15px;
  border-radius: 22px;
  background-color: #FFD200;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  color: #000000;
  cursor: pointer;
  :hover{
    background-color: #000000;
    color: #FFD200;
  }
`
*/
const EmbajadorStar = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 999;
  bottom: -1px;
  left: -1px;
  width: 22px;
  height: 22px;
  font-size: 15px;
  line-height: 15px;
  border-radius: 22px;
  img{
    float: left;
    width: 100%;
  }
  //background-color: #FFD200;
  //-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  //box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  //color: #000000;
  //cursor: pointer;
  /*
  :hover{
    background-color: #000000;
    color: #FFD200;
  }
  */
`

const Logo = styled.div`
    //background-color: skyblue;
    cursor: pointer;
    width: 45vw;

    .image{
      display: flex;
      align-items: center;
      width: 45vw;
      img{
        width: 100%;
      }
    }

    @media ${breakpoints.xs} {
      width: 240px;
      .image{
        display: flex;
        align-items: center;
        width: 200px;
        img{
          width: 100%;
        }
      }
    }
    @media ${breakpoints.sm} {
      //width: 400px;
    }
    @media ${breakpoints.md} {
      //width: 450px;
    }
    @media ${breakpoints.lg} {
      width: 240px;
    }
`
const SideActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
    .userTagWrapper{
      position: relative;
    }
  .notification_block{
    position: relative;
    .notification_button{
      cursor: pointer;
    }
    .notification_badge{
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1299;
      background-color: red;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      right: 2px;
      p{
        margin: 0;
        color: #FFFFFF;
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
  .button_contact{
    display: none;
  }
  .button_hamburger{
    display: flex;
    margin-left: 15px;
  }
  .button_logout{
    display: none;
  }
  .button_gotopanel{
    display: none;
  }
  @media ${breakpoints.xs} {
    //width: 400px;

  }
  @media ${breakpoints.sm} {
    .button_contact{
      display: flex;
    }

    .button_logout{
      display: flex;
    }
    .button_gotopanel{
      display: flex;
    }
  }
  @media ${breakpoints.md} {
    //width: 450px;
    .button_hamburger{
      display: none;
    }
  }
  @media ${breakpoints.lg} {
    width: 180px;
  }
`
const ModalStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  &.scrolleable{
    position: absolute;
    overflow: scroll;
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .inner_modal_embajador{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 6vw);
    height: auto;
    padding: 5vw;
    //background-color: #FFFFFF;
    outline: 0!important;
    border-radius: 16px;

    background-image: url("/images/back_videolanding.jpg");
      background-size: cover;
      background-position: center;
  }
  .inner_modal_panel{
    .actions{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 10px;
      .bt_go_panel{
        margin-bottom: 7px;
        width: 100%;
      }
      .bt_download_panel{
        margin-bottom: 7px;
        width: 100%;
      }
      .bt_tutorial_indicadores{
        width: 100%;
      }

    }
    .video{
      display: flex;
      width: 100%;
      margin-top: 10px;
      .player-wrapper{
        display: flex;
        width: 100%;
        height: 487px;
        overflow: hidden;
        border-radius: 10px;
        .react-player{
          display: flex;
          width: 100%;
        }
      }
      .temp_image_video{
        width: 100%;
        img{
          float: left;
          width: 100%;
        }
      }
    }

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 6vw);
    height: auto;
    padding: 5vw;
    background-color: #FFFFFF;
    outline: 0!important;
    border-radius: 16px;

    .title{
      width: 73vw;
      margin-top: 0;
      p{
        font-size: 4vw;
        line-height: 4.5vw;
        font-weight: 600;
        width: 100%;
        text-align: center;
        margin: 0;
      }
    }

    .text{
      width: 73vw;
      margin-top: 3vw;
      //margin-bottom: 30px;
      p{
        font-size: 3.4vw;
        line-height: 4.1vw;
        width: 100%;
        text-align: center;
        margin: 0;
      }
    }
  }
  .inner_modal_contact_message{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    padding: 5vw;
    background-color: #FFFFFF;
    outline: 0!important;
    border-radius: 0;
    .texto_mensaje{
      width: calc(100% - 50px);
      text-align: center;
      .highlighted{
        font-size: 18px;
        line-height: 30px;
        margin-top: 10px;
      }
    }
    .errorMessages{
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;
      border: 1px dashed #F20000;
      background-color: #FF9494;
      color: #000000;
      padding: 6px 10px 6px 10px;
      font-size: 15px;
      line-height: 19px;
      p{

      }
    }
    .successMessages{
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;
      background-color: #B8FF6D;
      //#F7DECC,#AD8D75,#B8FF6D,#1B110A,#E0C0A8,#F7DECC,#AD8D75,#B8FF6D
      border: 1px dashed #33E46D;
      color: #000000;
      padding: 6px 10px 6px 10px;
      font-size: 15px;
      line-height: 19px;
    }

    .title{
      width: 80vw;
      margin-top: 0;
      //margin-bottom: 6vw;
      p{
        text-align: left;
        font-size: 7vw;
        line-height: 8.5vw;
        width: 100%;
      }
    }

    .contact_form{
      display: flex;
      flex-direction: column;
      width: 100%;

      .field_channel{
        margin-bottom: 20px;
      }
      .field_subject{
        margin-bottom: 20px;
      }
      .field_message{
        margin-bottom: 10px;
      }
      .modal_actions{
        .loader{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 250px;
          height: 40px;
          //margin-top: 6px;
          svg{
            color: #FFD200;
          }
        }
      }

      .is_invalid{
        border: 1px solid red!important;
      }
      .fix_is_invalid{
        border: 0;
      }

      .field{
        label{
          display: flex;
          width: 100%;
          font-size: 16px;
          line-height: 16px;
          font-weight: 600;
          color: #000000;
          margin-bottom: 7px;
        }
        input{
          display: flex;
          padding: 10px;
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          width: 100%;
        }
        textarea{
          display: flex;
          padding: 10px;
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          width: 100%;
        }
        select{
          display: flex;
          padding: 10px;
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          width: 100%;
        }
      }
    }
  }

  &.scrolleable{
    padding-top: 0;
    padding-bottom: 0;
  }
  .inner_modal_embajador{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 100%;
    outline: 0!important;
    border-radius: 0;
    /*
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 600px;
      min-height: 100%;
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 0;
    */

      .box_embajador{
        display: flex;
        flex-direction: column;
        margin-top: 43px;
        box-sizing: border-box;
        border-radius: 16px;
        padding: 25px 25px 25px 25px;

        background-color: rgba(0, 0, 0, 0.5);

        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);

        .station_select{
          display: flex;
          flex-direction: column;
          margin-bottom: 4vw;
          label{
            margin: 0 0 3px 0;
            font-weight: 600;
          }
          select{
            display: flex;
            padding: 10px;
            border: 1px solid #CCCCCC;
            height: 46px;
            border-radius: 6px;
          }
        }
        .update_date{
          width: 100%;
          text-align: center;
          font-size: 14px;
          margin-top: 20px;
          color: #FFFFFF;
        }
        .top{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          //background-color: pink;
          .text{
            /*
            width: calc(100% - 32vw);
            margin-right: 2vw;
            */
            .name{
              color: #FFFFFF;
              font-weight: 600;
              font-size: 4vw;
              line-height: 4.5vw;
            }
            .station{
              color: #999999;
              font-weight: 600;
              font-size: 3vw;
              line-height: 4.2vw
            }
          }
          .logo{
            width: 30vw;
            img{
              float: left;
              width: 100%;
            }
          }
        }
        .bottom{
          display: flex;
          margin-top: 4vw;
          //background-color: skyblue;
          .metrics{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .metric{
              display: flex;
              flex-direction: column;
              align-items: center;
              background-color: #FFD200;
              width: calc(50% - 2vw);
              padding-top: 2vw;
              padding-bottom: 2vw;
              border-right: 1px solid #FFFFFF;
              border-left: 1px solid #FFFFFF;
              border-radius: 6px;
              border-bottom: 2px solid #FFFFFF;
              .title{
                width: calc(100% - 6vw);
                text-align: center;
                color: #D8010F;
                font-weight: 600;
                font-size: 3.2vw;
                line-height: 4.2vw;
                border-bottom: 1px solid #FFFFFF;
                padding-bottom: 1vw;
                margin-bottom: 1vw;
              }
              .value{
                font-weight: 800;
                font-size: 6vw;
                line-height: 6vw;
                letter-spacing: -2px;
                color: #FFFFFF;
                margin-top: 0.6vw;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
      .ranking{
        margin-top: 6vw;

        .table_top{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-top: 2.6vw;
          padding-bottom: 2.6vw;
          border: 2px solid #FFD200;
          -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
          box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);

          .table_title{
            width: 100%;
            text-align: center;
            font-weight: 700;
            font-size: 3.2vw;
            line-height: 3.2vw;
          }
        }
        .table_bottom{
          width: 100%;
          display: flex;
          flex-direction: column;
          .table_bottom_element{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.6vw;
            margin-bottom: 0.6vw;
            padding-top: 0.6vw;
            padding-bottom: 0.6vw;
            &.back2{
              background-color: #F7F6F6;
            }
            .table_content{

              width: 100%;
              text-align: center;
              &.posicion{
                font-size: 3.6vw;
                font-weight: 700;
                padding-right: 2vw;
                padding-left: 2vw
              }
              &.boca{
                font-size: 3.6vw;
                font-weight: 700;
                padding-right: 2vw;
                padding-left: 2vw
              }
              &.razonsocial{
                font-size: 2.6vw;
                line-height: 3.4vw;
                font-weight: 700;
                text-transform: uppercase;
                padding-right: 2vw;
                padding-left: 2vw;
                padding: 1vw 2vw 1vw 2vw;
              }
            }
          }
        }
      }

    }

  @media ${breakpoints.xs} {
    .inner_modal_embajador{
      display: flex;
      flex-direction: column;
      width: 100%;//calc(100% - 30px);
      height: auto;
      padding: 25px;
      //background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 0;



      .box_embajador{
        display: flex;
        flex-direction: column;
        margin-top: 43px;
        box-sizing: border-box;
        border-radius: 16px;
        padding: 25px 25px 25px 25px;



        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);

        .station_select{
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          label{
            margin: 0 0 3px 0;
            font-weight: 600;
          }
          select{
            display: flex;
            padding: 10px;
            border: 1px solid #CCCCCC;
            height: 46px;
            border-radius: 6px;
          }
        }
        .top{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          //background-color: pink;
          .text{
            .name{
              font-size: 20px;
              line-height: 24px;
            }
            .station{
              font-size: 15px;
              line-height: 21px;
            }
          }
          .logo{
            width: 120px;
            img{
              float: left;
              width: 100%;
            }
          }
        }
        .bottom{
          display: flex;
          margin-top: 20px;
          //background-color: skyblue;
          .metrics{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            .metric{
              display: flex;
              flex-direction: column;
              align-items: center;
              background-color: #FFD200;
              width: calc(50% - 10px);
              padding-top: 10px;
              padding-bottom: 10px;
              border-right: 1px solid #FFFFFF;
              border-left: 1px solid #FFFFFF;
              border-radius: 6px;
              border-bottom: 0;
              .title{
                width: calc(100% - 30px);
                text-align: center;
                color: #D8010F;
                font-weight: 600;
                font-size: 16px;
                line-height: 21px;
                border-bottom: 1px solid #FFFFFF;
                padding-bottom: 5px;
                margin-bottom: 5px;
              }
              .value{
                font-weight: 800;
                font-size: 30px;
                line-height: 30px;
                letter-spacing: -2px;
                color: #FFFFFF;
                margin-top: 3px;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
      .ranking{
        margin-top: 30px;

        .table_top{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-top: 13px;
          padding-bottom: 13px;
          border: 2px solid #FFD200;
          -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
          box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);

          .table_title{
            width: 100%;
            text-align: center;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
          }
        }
        .table_bottom{
          width: 100%;
          display: flex;
          flex-direction: column;
          .table_bottom_element{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 3px;
            margin-bottom: 3px;
            padding-top: 3px;
            padding-bottom: 3px;
            &.back2{
              background-color: #F7F6F6;
            }
            .table_content{

              width: 100%;
              text-align: center;
              &.posicion{
                font-size: 18px;
                font-weight: 700;
                padding-right: 10px;
                padding-left: 10px
              }
              &.boca{
                font-size: 18px;
                font-weight: 700;
                padding-right: 10px;
                padding-left: 10px
              }
              &.razonsocial{
                font-size: 13px;
                line-height: 17px;
                font-weight: 700;
                text-transform: uppercase;
                padding-right: 10px;
                padding-left: 10px;
                padding: 5px 10px 5px 10px;
              }
            }
          }
        }
      }
    }

    .inner_modal_panel{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 30px);
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;

      .title{

        width: calc(100% - 45px);
        margin-top: 0;
        //background-color: pink;
        //margin-bottom: 30px;
        p{
          font-size: 22px;
          line-height: 27px;
          font-weight: 600;
          width: 100%;
          text-align: center;
          margin: 0;
        }
      }

      .text{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: calc(100% - 45px);
        margin-top: 5px;
        //margin-bottom: 30px;
        p{
          font-size: 18px;
          line-height: 22px;
          width: calc(100% - 100px);
          text-align: center;
          margin: 0;
        }
      }

    }
    .inner_modal_contact_message{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 30px);
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;

      .title{
        width: calc(100% - 40px);
        margin-top: 0;
        margin-bottom: 0;
        p{
          text-align: left;
          font-size: 37px;
          line-height: 40px;
          width: 100%;
        }
      }

    }
  }
  @media ${breakpoints.sm} {
    &.scrolleable{
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .inner_modal_embajador{
      display: flex;
      flex-direction: column;
      width: 600px;
      height: auto;
      min-height: unset;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;

      .box_embajador{
        display: flex;
        flex-direction: column;
        margin-top: 43px;
        box-sizing: border-box;
        border-radius: 16px;
        padding: 25px 25px 25px 25px;

        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);

        .station_select{
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          label{
            margin: 0 0 3px 0;
            font-weight: 600;
          }
          select{
            display: flex;
            padding: 10px;
            border: 1px solid #CCCCCC;
            height: 46px;
            border-radius: 6px;
          }
        }
        .top{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          //background-color: pink;
          .text{
            .name{
              font-size: 20px;
              line-height: 24px;
            }
            .station{
              font-size: 15px;
              line-height: 21px;
            }
          }
        }
        .bottom{
          display: flex;
          margin-top: 20px;
          //background-color: skyblue;
          .metrics{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .metric{
              display: flex;
              flex-direction: column;
              align-items: center;
              background-color: #FFD200;
              padding-top: 10px;
              padding-bottom: 10px;
              border-right: 1px solid #FFFFFF;
              border-left: 1px solid #FFFFFF;
              border-radius: 6px;
              .title{
                width: calc(100% - 30px);
                text-align: center;
                color: #D8010F;
                font-weight: 600;
                font-size: 16px;
                line-height: 21px;
                border-bottom: 1px solid #FFFFFF;
                padding-bottom: 5px;
                margin-bottom: 5px;
              }
              .value{
                font-weight: 800;
                font-size: 30px;
                line-height: 30px;
                letter-spacing: -2px;
                color: #FFFFFF;
                margin-top: 3px;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
      .ranking{
        margin-top: 30px;

        .table_top{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-top: 13px;
          padding-bottom: 13px;
          border: 2px solid #FFD200;
          -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
          box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);

          .table_title{
            width: 100%;
            text-align: center;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
          }
        }
        .table_bottom{
          width: 100%;
          display: flex;
          flex-direction: column;
          .table_bottom_element{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 3px;
            margin-bottom: 3px;
            padding-top: 3px;
            padding-bottom: 3px;
            &.back2{
              background-color: #F7F6F6;
            }
            .table_content{

              width: 100%;
              text-align: center;
              &.posicion{
                font-size: 18px;
                font-weight: 700;
                padding-right: 10px;
                padding-left: 10px
              }
              &.boca{
                font-size: 18px;
                font-weight: 700;
                padding-right: 10px;
                padding-left: 10px
              }
              &.razonsocial{
                font-size: 13px;
                line-height: 17px;
                font-weight: 700;
                text-transform: uppercase;
                padding-right: 10px;
                padding-left: 10px;
                padding: 5px 10px 5px 10px;
              }
            }
          }
        }
      }

    }
    .inner_modal_panel{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 600px;
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;

      .title{
        width: calc(100% - 45px);
        margin-top: 0;
        //background-color: pink;
        //margin-bottom: 30px;
        p{
          font-size: 22px;
          line-height: 27px;
          font-weight: 600;
          width: 100%;
          text-align: center;
          margin: 0;
        }
      }

      .text{
        width: calc(100% - 45px);
        margin-top: 5px;
        //margin-bottom: 30px;
        p{
          font-size: 18px;
          line-height: 22px;
          width: calc(100% - 30px);
          text-align: center;
          margin: 0;
        }
      }
    }
    .inner_modal_contact_message{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 700px;
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;

      .title{
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        p{
          text-align: left;
          font-size: 37px;
          line-height: 40px;
          width: 100%;
        }
      }

    }
  }
  @media ${breakpoints.md} {
    .inner_modal_panel{
      width: 900px;
      .actions{
        flex-direction: row;
        .bt_go_panel{
          margin-bottom: 0;
          width: calc(33% - 3px);
        }
        .bt_download_panel{
          margin-bottom: 0;
          width: calc(33% - 3px);
        }
        .bt_tutorial_indicadores{
          width: calc(33% - 3px);
        }
      }
      .text{
        width: calc(100% - 45px);
        margin-top: 5px;
        //margin-bottom: 30px;
        p{
          font-size: 18px;
          line-height: 22px;
          width: calc(100% - 150px);
          text-align: center;
          margin: 0;
        }
      }
    }
  }
  @media ${breakpoints.lg} {

  }
`
const NotificationPop = styled.div`
  position: absolute;
  right: 10px;
  top: 70px;
  border-radius: 8px;
  border: 1px solid #e6e7eb;
  z-index: 9999;
  .inner{
    width: 96vw;
    height: 435px;
    border-radius: 8px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);

    &.no_notification_height{
      height: 72px;
    }

    .actions{
      display: flex;
      position: absolute;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      .bt_viewed{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        border: 1px solid #F5F6FA;
        background-color: #F5F6FA;
        border-top-color: #d0d9db;

        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        cursor: pointer;
        p{
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          text-transform: uppercase;
          margin: 0;
          text-decoration: none;
        }
        :hover{
          border: 1px solid #FFD200;
          background-color: #FFD200;
          border-top-color: #d0d9db;
          p{
            text-decoration: none;
          }
        }

      }
      .loader_viewall{
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          color: #FFD200;
        }
      }
    }
  }
  :before {
    content: "";
    position: absolute;
    //top: -webkit-calc(100% - 10px); /*may require prefix for old browser support*/
    top: -9px; /*i.e. half the height*/
    left: calc(100% - 83px);
    height: 15px;
    width: 15px;
    background: #FFFFFF;
    transform: rotate(225deg);
    border-bottom:inherit;
    border-right:inherit;
    box-shadow:inherit;

  }


  .no_notifications{
    display: flex;
    width: 100%;
    height: 70px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    p{
      margin: 0;
      font-weight: 500;
    }
  }
  ul.notification_items{
    list-style: none;
    display: flex;
    width: 100%;

    flex-direction: column;
    margin: 0;
    padding: 0;

    overflow-x: hidden;
    overflow-y: auto;

    &.notification_items_not_new{
      height: 435px!important;
    }
    &.notification_items_have_new{
      //height: 390px!important;
    }

    li.notification_item{
      list-style: none;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      padding-top: 13px;
      padding-bottom: 13px;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 1px;
      border-bottom: 1px solid #e6e7ed;
      //
      &.with_goto{
        cursor: pointer;
        :hover{
          background-color: #fafbff;
        }
      }
      &.active{

      }
      &.inactive{
        opacity: 0.5;
      }
      .notification_title{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        p{
          font-weight: 600;
          font-size: 15px;
          padding-top: 5px;
          margin: 0;
        }
        .notification_icon{
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 19px;
          margin-right: 5px;
          color: #FFD200;
        }
        .goto_icon{
          font-size: 18px;
          margin-left: auto;
        }
      }
      .notification_description{
        margin-top: 0;
        p{
          font-size: 14px;
          margin: 0;
          padding-left: 25px;
        }
      }
    }
    li.notification_item:last-child{
      border-bottom: 0;
    }
  }
  @media ${breakpoints.xs} {
    right: 20px;
    top: 70px;
    .inner{
      width: 400px;
      height: 435px;
    }
    :before {
      content: "";
      position: absolute;
      top: -9px;
      left: calc(100% - 58px);
      height: 15px;
      width: 15px;
    }
  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`
const ButtonClose = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  cursor: pointer;
  right: 20px;
  top: 20px;
  z-index: 9;
  .icon{
    display: flex;
    font-size: 45px;
    color: #FFD200;
  }
  :hover{
    .icon{
      color: #000000;
    }
  }
`


const Header = (props) => {

  let location = useLocation();

  const refNotification = useRef(null);

  const { logout,
          activeRoute,
          setActiveRoute } = useContext(AuthContext);

  const { gotoData,
          setGotoData,
          setCourse,
          userData,
          sendContactMessage,
          notification_status_change,
          contact_profile,
          getNotifications,
          get_notifications,
          notifications,
          setNotifications,
          setNotificationsViewed,
          visibilityMenuMobile,
          setVisibilityMenuMobile,
          openModalContacto,
          setOpenModalContacto,
          openModalEmbajadores,
          setOpenModalEmbajadores,
          getEmbajadores,
          notificationPostSelected, 
          setNotificationPostSelected } = useContext(ContentContext);

  const [ showNotificationPop, setShowNotificationPop ] = useState(false);

  const handleOpenModalContacto = () => {

    setContactChannel("");
    setContactSubject("");
    setContactText("");

    setOpenModalContacto(!openModalContacto);
  }
  const handleCloseModalContacto = () => setOpenModalContacto(false);
  const handleCloseModalEmbajadores = () => setOpenModalEmbajadores(false);

  const [ contactSubject, setContactSubject ] = useState("");
  const [ contactText, setContactText ] = useState("");
  const [ contactChannel, setContactChannel ] = useState("");
  const [ loadingContactForm, setLoadingContactForm ] = useState(false);
  const [ successFormContact, setSuccessFormContact ] = useState(null);
  const [ counterNotifications, setCounterNotifications ] = useState(null);
  const [ openModalPanel, setOpenModalPanel ] = useState(null);
  const [ embajadoresRanking, setEmbajadoresRanking ] = useState(null);
  const [ embajadoresUserData, setEmbajadoresUserData ] = useState(null);
  const [ embajadoresStationSelected, setEmbajadoresStationSelected ] = useState(null);
  const [ swapVideo, setSwapVideo ] = useState(true);
  const [ loadingViewAll, setLoadingViewAll ] = useState(false);

  const history = useHistory();

  const [ haveErrorContact, setHaveErrorContact ] = useState({
    //subject: { status: false, message: "" },
    message: { status: false, message: "" },
    //channel: { status: false, message: "" },
	});

  const contactChannels = ["Soporte App", "Otros"];

  const goRoute = (route) => {
    setActiveRoute(route);
    history.push('/'+route);
  }

  useEffect(() => {
    const pathNameSplited = location.pathname.split('/');
    setActiveRoute(pathNameSplited[1]);

    get_notifications();
    //getNotifications();

    // const responseEmbajadores = getEmbajadores();
    // responseEmbajadores.then((res) =>{
    //   if(res && res.ranking){
    //     //setEmbajadoresRanking(res.ranking);
    //     setEmbajadoresUserData(res.user_ranking);
    //     handleEmbajadoresStationSelected(res.user_ranking[0]);
    //   }
    // })

    return () => {

    }
  }, [])

  useEffect(() => {
    return () => {}
  }, [userData])

  useEffect(() => {

    if(notifications){
      setCounterNotifications(notifications.filter(notification => notification.status !== 3).length);
    }

    return () => {}
  }, [notifications])


  const handleSendContact = () => {

    let haveErrorProcessReset = {...haveErrorContact};
    //haveErrorProcessReset.channel.status = false;
    //haveErrorProcessReset.subject.status = false;
    haveErrorProcessReset.message.status = false;
    setHaveErrorContact(haveErrorProcessReset);

    let error = false;
    let haveErrorProcess = {...haveErrorContact};

    // if(contactChannel.length <= 0){
    //   error = true;
    //   haveErrorProcess.channel.status = true;
    // }
    // if(contactSubject.length <= 0){
    //   error = true;
    //   haveErrorProcess.subject.status = true;
    // }
    if(contactText.length <= 0){
      error = true;
      haveErrorProcess.message.status = true;
    }

    setHaveErrorContact(haveErrorProcess);

    if(!error){
      const payload = {
        channel: userData?.channels[0].name,//contactChannel,
        //title: contactSubject,
        os_system: "",
        message: contactText,
      }
      // console.log("payload", payload);
      // return null;

      setLoadingContactForm(true);
      //return null;
      const response = contact_profile(payload);//sendContactMessage(payload);
      response.then(res => {
        setLoadingContactForm(false);
        if(res.status){
          setSuccessFormContact(true);
          setContactChannel("");
          setContactSubject("");
          setContactText("");
        }else{
          setSuccessFormContact(false);
        }
        setTimeout(closeFormContactErrorMessage, 3500);
      })
    }

  }

  const handleClickOutside = (event) => {
    if (refNotification.current && !refNotification.current.contains(event.target)) {
      setShowNotificationPop(false);
    }
  }


  const handleNotificationPop = () => {

    setShowNotificationPop(!showNotificationPop);

  }

  useEffect(() => {
    //alert("storage.getItem('show_embajadores'): " + (storage.getItem('show_embajadores') == 'false'));
    if(showNotificationPop){
      document.addEventListener("mousedown", handleClickOutside);
    }else{
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [showNotificationPop])


  const handleAllViewed = () => {

    setLoadingViewAll(true);

    if(notifications){
      let notification_status = [];
      notifications.map((notification, index) => {
        if(notification.status !== 3){
          notification_status.push({_id: notification._id})
        }
      })

      const payload = {notification_id: JSON.stringify(notification_status), status: 3};
      // console.log("payload", payload);
      // return null;
      const result = notification_status_change(payload);//setNotificationsViewed();

      result.then((res) => {

        //console.log("res", res);

        //console.log("notifications", notifications)
        const notifications_udpated = notifications.map((n, i) => {
          n.status = 3;
          return n;
        })
        //console.log("notifications_udpated", notifications_udpated)
        setNotifications(notifications_udpated);
        setLoadingViewAll(false);
        // if(res.status){
        //   const notificationViewed = notifications.map((notification, index) => {
        //     notification.status = 1;
        //     return notification;
        //   })
        //   setNotifications(notificationViewed);
        // }
      })
    }



    //setCounterNotifications(0);

  }

  const handleViewed = (notification_id) => {

    setLoadingViewAll(true);

    const payload = {notification_id: notification_id, status: 3};
    const result = notification_status_change(payload);//setNotificationsViewed();

    const notifications_update = notifications.filter(n => n._id === notification_id);
    if(notifications_update.length){
      notifications_update[0].status = 3;
    }
    setNotifications(notifications_update);

    result.then((res) => {
      /*
      const notifications_udpated = notifications.map((n, i) => {
        n.status = 3;
        return n;
      })
      setNotifications(notifications_udpated);
      */
      setLoadingViewAll(false);
    })

  }

  const handleShowMenu = () => {
    setVisibilityMenuMobile(!visibilityMenuMobile);
  }

  const handleAllNotifications = () => {

    alert("handleAllNotifications");

  }

  const closeFormContactErrorMessage = () => {
    setSuccessFormContact(null);
  }

  const handleGotoPanel = () => {

    setOpenModalPanel(!openModalPanel);
    //window.open("http://panel.dotspanel.com/#/auth", '_blank').focus();
  }

  const handleGoEmbajadores = () => {

    setOpenModalEmbajadores(!openModalEmbajadores);

  }

  const handleEmbajadoresStationSelected = (embajadoresStation) => {
    if(embajadoresStation){
      setEmbajadoresStationSelected({ boca: embajadoresStation.boca,
                                      //meta_diaria: embajadoresStation.meta_diaria,
                                      meta_total: embajadoresStation.meta_total,
                                      resultado: embajadoresStation.resultado,
                                      /*posicion: embajadoresStation.posicion*/ })
    }
  }

  const setEmbajadorStation = (embajadoresStationId) => {

    const embajador_station = embajadoresUserData.filter(el => el.boca == embajadoresStationId);
    handleEmbajadoresStationSelected(embajador_station[0]);

  }
  const handleVideoProgress = ((progress) => {
    /*
    if(!onVideoEnded && progress.played >= 0.95){
      setOnVideoEnded(true);
    };
    */
  });

  const handleVideoStart = () => {
    /*
    setOnVideoEnded(false);
    handleOnEndedCalled.current = false;
    setOnVideoProgressStatus(true);
    */
  }

  const handleVideoStop = () => {
    /*
    setOnVideoEnded(true);
    handleOnEndedCalled.current = true;
    setOnVideoProgressStatus(false);
    */
  }

  const endTraining = (course) => {
    /*
    getCourseData();
    handleApprovedTraining(course);
    updateTrainingCertificates(trainings, course.id_curse);
    updateTrainingStates(trainings);
    */
  }

  const handleClicGoto = (go_to, notification_id, status) => {

    if(status !== 3){
      handleViewed(notification_id);
    }

    if(go_to.type === "interno"){

      setGotoData({path: go_to.path, _id: go_to.id_content});

      if(go_to.path === "course"){
        setCourse(null);
        setActiveRoute("campus");
        history.push(`/campus/course/${go_to.id_content}`);
      }
      if(go_to.path === "module"){
        setCourse(null);
        setActiveRoute("campus");
        history.push(`/campus/module/${go_to.id_content}`);
      }
      if(go_to.path === "comment"){

        setNotificationPostSelected(go_to.post_id);

        setCourse(null);
        //setActiveRoute("campus");
        //history.push(`/campus/module/${go_to.id_content}`);
      }
    }
    if(go_to.type === "externo"){
      window.open(go_to.path, '_blank').focus();
    }

    setShowNotificationPop(false);

  }

  const termsURL = '/pdfs/Funcionalidades_Nuevo_Panel_de_Nominas_e_Indicadores_1404_22.pdf';

  const handleDownloadDocument = () => {
    downloadFileFromUrl(termsURL, 'funcionalidades_nuevo_panel_de_nominas_e_indicadores.pdf');
  }

  const handleGoToPanelFromPop = () => {

    window.open("http://panel.dotspanel.com/#/auth", '_blank').focus();

  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    history.push("/");
    logout();
  }

  return (
    <Wrapper>

      {/*<Encuesta />*/}
      <ReactTooltip className="react_tooltip" place="bottom" data-offset={{top: 5}} effect="solid" backgroundColor="#FFD200" textColor="#000000" />
      <ModalStyled
        open={openModalEmbajadores}
        onClose={handleCloseModalEmbajadores}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        scroll={'paper'}
        className="scrolleable"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalEmbajadores}>
          <div className="inner_modal_embajador">
            <ButtonClose
              onClick={() => handleGoEmbajadores()}
            >
              <div className="icon">
                <RiCloseCircleLine />
              </div>
            </ButtonClose>

            {embajadoresStationSelected &&

            <div className="box_embajador">

              {embajadoresUserData.length > 1 &&
                <div className="station_select">
                  <label>Seleccioná una Boca</label>
                  <select
                    value={embajadoresStationSelected.boca}
                    onChange={(e) => { setEmbajadorStation(e.target.value); } }
                    className={""}
                  >
                    <option vlaue="">Selecciona una opción</option>
                    {
                      embajadoresUserData.map((el, index) => {

                        return(
                          <option vlaue={el.boca}>{el.boca}</option>
                        )
                      })
                    }
                  </select>
                </div>
              }

              <div className="top">



                {userData &&
                <div className="text">
                  <div className="name">{userData.name + ' ' + userData.last_name}</div>
                  <div className="station">Boca/Estación: {embajadoresStationSelected.boca}</div>
                </div>
                }
                <div className="logo">
                  <img src="/images/logo-shellvpower.png" />
                </div>
              </div>

              <div className="bottom">
                <div className="metrics">
                  {/*
                  <div className="metric">
                    <div className="title">Meta Diaria</div>
                    <div className="value">{embajadoresStationSelected.meta_diaria}</div>
                  </div>
                  */}
                  <div className="metric">
                    <div className="title">Meta Total</div>
                    <div className="value">{embajadoresStationSelected.meta_total}</div>
                  </div>
                  <div className="metric">
                    <div className="title">Resultados</div>
                    <div className="value">{embajadoresStationSelected?.resultado ? embajadoresStationSelected?.resultado : "---"}</div>
                  </div>
                  {/*
                  <div className="metric">
                    <div className="title">Tu Posición</div>
                    <div className="value">{embajadoresStationSelected.posicion}</div>
                  </div>
                  */}
                </div>
              </div>

              <div className="update_date">Vigencia desde el día 1 de julio.<br/>Actualización de los resultados finales, cierre al 31 de julio inclusive.</div>

            </div>

            }

            {embajadoresRanking &&
            <div className="ranking">
              <div className="table_top">
                <div className="table_title posicion">Posición</div>
                <div className="table_title boca">Boca</div>
                <div className="table_title razonsocial">Razon Social</div>
              </div>
              <div className="table_bottom">
                {embajadoresRanking.map((el, index) => {
                  let back = index % 2 == 0 ? 'back2' : '';
                  return(
                  <div className={`table_bottom_element ${back}`}>
                    <div className="table_content posicion">{el.Poscion_en_el_Ranking}</div>
                    <div className="table_content boca">{el.boca}</div>
                    <div className="table_content razonsocial">{el.name_store}</div>
                  </div>
                  )
                })}
              </div>
            </div>
            }
          </div>

        </Fade>
      </ModalStyled>

      <ModalStyled

        open={openModalPanel}
        onClose={handleCloseModalContacto}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        >
        <Fade in={openModalPanel}>



          <div className="inner_modal_panel">
            <ButtonClose
              onClick={() => handleGotoPanel()}
            >
              <div className="icon">
                <RiCloseCircleLine />
              </div>
            </ButtonClose>

            <div className="title">
              <p>Tutorial Navegacion Panel</p>
            </div>
            <div className="text">
              <p>Conocé el paso a paso para la extracción de reportes, visualizar nuevos objetivos y carga de nóminas</p>
            </div>

            <div className="video">
              {/*swapVideo ?
              <div className="temp_image_video">
                <img src="/images/panel_tut_temp.jpg" />
              </div>
              :
              <div className="temp_image_video">
                <img src="/images/panel_tut_temp1.jpg" />
              </div>
              */}
              {swapVideo ?
              <div className='player-wrapper'>
                <ReactPlayer
                  className='react-player'
                  url={"/videos/Tutorial_Panel.mp4"}//nomina https://vimeo.com/699630686
                  width='100%'
                  height='100%'
                  volume="1"
                  controls={true}
                  onProgress={(progress) => { handleVideoProgress(progress)}}
                  onPlay={handleVideoStart}
                  onStart={handleVideoStart}
                  onStop={handleVideoStop}
                  config={{ file: {
                    attributes: {
                      controlsList: 'nodownload'
                    },
                  },
                  vimeo: {
                    preload: true,
                    muted: 1,
                    controls: true,
                    //playerOptions: vimeoConfig,
                  },
                }}
                />
              </div>
              :
              <div className='player-wrapper'>
                <ReactPlayer
                  className='react-player'
                  url={"/videos/Tutorial_Indicadores.mp4"}//indicadores https://vimeo.com/699898910
                  width='100%'
                  height='100%'
                  volume="1"
                  controls={true}
                  onProgress={(progress) => { handleVideoProgress(progress)}}
                  onPlay={handleVideoStart}
                  onStart={handleVideoStart}
                  onStop={handleVideoStop}
                  config={{ file: {
                    attributes: {
                      controlsList: 'nodownload'
                    }
                  },
                  vimeo: {
                    preload: true,
                    muted: 1,
                    controls: true,
                    //playerOptions: vimeoConfig,
                  },
                }}
                />
              </div>
              }

            </div>

            <div className="actions">
              {/*
              <ButtonYellow
                className="bt_go_panel"
                onClick={() => handleGoToPanelFromPop()}
                disabled={false}
                css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
              >
                <p>IR AL PANEL</p>
              </ButtonYellow>
              */}

              <ButtonYellow
                className="bt_tutorial_indicadores"
                onClick={() => setSwapVideo(!swapVideo)}
                disabled={false}
                css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
              >
                <p>TUTORIAL INDICADORES</p>
              </ButtonYellow>

              <ButtonYellow
                className="bt_download_panel"
                onClick={() => handleDownloadDocument()}
                disabled={false}
                css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
              >
                <p>DESCARGAR DOCUMENTO</p>
              </ButtonYellow>



              <ButtonYellow
                className="bt_tutorial_indicadores"
                onClick={() => setSwapVideo(!swapVideo)}
                disabled={false}
                css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
              >
                <p>TUTORIAL NÓMINAS</p>
              </ButtonYellow>

              {/*swapVideo ?
                <ButtonYellow
                  className="bt_tutorial_indicadores"
                  onClick={() => setSwapVideo(!swapVideo)}
                  disabled={false}
                  css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                >
                  <p>TUTORIAL INDICADORES</p>
                </ButtonYellow>
              :
                <ButtonYellow
                  className="bt_tutorial_indicadores"
                  onClick={() => setSwapVideo(!swapVideo)}
                  disabled={false}
                  css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                >
                  <p>TUTORIAL NÓMINAS</p>
                </ButtonYellow>
              */}

            </div>
            {/*
            <div className="title">
              <p>Próximamente se habilitará el Panel con reportes, objetivos y carga de nóminas.</p>
            </div>
            <div className="text">
              <p>Mientras tanto pueden enviar sus altas y/o bajas a <span className="highlight">contacto@somosprotagonistas.com.ar.</span><br/><br/><strong>¡Gracias!</strong></p>
            </div>
            */}
          </div>

        </Fade>
      </ModalStyled>

      <ModalStyled

        open={openModalContacto}
        onClose={handleCloseModalContacto}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        /*BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}*/
      >
        <Fade in={openModalContacto}>



          <div className="inner_modal_contact_message">
            <ButtonClose
              onClick={() => handleOpenModalContacto()}
            >
              <div className="icon">
                <RiCloseCircleLine />
              </div>
            </ButtonClose>

            <div className='texto_mensaje'>
              Si tenés alguna duda o consulta, envíanos un mail a contacto@somosprotagonistas.com.ar<br/><span className='highlighted'><strong>¡Gracias!</strong></span>
            </div>

            {/*
            <div className="title">
              <p>¡Dejanos tu mensaje!</p>
            </div>

            <div className="contact_form">

              <div className="field field_message">
                <label htmlFor="contact_text">Mensaje</label>
                <textarea
                  type="password"
                  id="contact_text"
                  name="contact_text"
                  onChange={(e) => setContactText(e.target.value)}
                  value={contactText}
                  className={haveErrorContact.message.status ? "is_invalid" : ""}
                ></textarea>
              </div>


              {successFormContact !== null ?
                successFormContact ?
                <div className="successMessages">
                  <div className="innerSuccessMessages">
                  <strong>El mensaje se envió con éxito.</strong><br/>Será respondido vía mail en un plazo de 24hs hábiles.<br/><strong>¡Gracias!</strong>
                  </div>
                </div>
                :
                <div className="errorMessages">
                  <div className="innerErrorMessages">
                    Hubo un error al intentar enviar el mensaje.
                  </div>
                </div>
                : <></>
              }

              <div className="modal_actions">

                {loadingContactForm ?
                  <div className="loader">
                    <CircularProgress size={30} />
                  </div>
                  :
                  <ButtonYellow
                    className="bt_login"
                    onClick={() => handleSendContact()}
                    disabled={false}
                    css_styles={{background:{normal: '#FFD200', hover: '#000000'}, width: '250px'}}
                  >
                    <p>ENVIAR</p>
                  </ButtonYellow>
                }

              </div>

            </div>
            */}




          </div>
        </Fade>
      </ModalStyled>

      <Logo onClick={() => goRoute('')}>
        <div className="image">
          <img src="/logo_header.png" alt="Somos Protagonistas"/>
        </div>
      </Logo>

      {/*
      <MenuStyled>
          <ButtonHeader
            onClick={() => goRoute('')}
            className={`${activeRoute === '' ? 'selected' : ''}`}
          >
            <div className="icon">
              <GoMegaphone />
            </div>
            <p>Muro</p>
          </ButtonHeader>

          <ButtonHeader
            onClick={() => goRoute('campus')}
            className={`${activeRoute === 'campus' ? 'selected' : ''}`}
          >
            <div className="icon">
              <BiBookBookmark />
            </div>
            <p>Campus</p>
          </ButtonHeader>

          <ButtonHeader
            onClick={() => goRoute('productos')}
            className={`${activeRoute === 'productos' ? 'selected' : ''}`}
          >
            <div className="icon">
              <IoBasketOutline />
            </div>
            <p>Productos</p>
          </ButtonHeader>

          {(userData?.rol == "Jefe de Turno" ||
            userData?.rol == "Territory Manager" ||
            userData?.rol == "District Manager" ||
            userData?.rol == "Empresario / Gerente" ||
            userData?.captain == true) &&
            <>
              <ButtonHeader aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} >
                <div className="icon">
                  <IoSettingsOutline />
                </div>
                <p>Panel</p>
              </ButtonHeader>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => {handleGoToPanelFromPop(); handleClose();}}>Ir al Panel</MenuItem>
                <MenuItem onClick={() => {handleGotoPanel(); handleClose();}}>Tutoriales</MenuItem>
              </Menu>
            </>
          }


          <ButtonHeader
            onClick={() => goRoute('trivia')}
            className={`${activeRoute === 'trivia' ? 'selected' : ''}`}
          >
            <div className="icon">
              <IoExtensionPuzzleOutline />
            </div>
            <p>Trivia</p>
          </ButtonHeader>

          <ButtonHeader
            onClick={() => goRoute('shellbox')}
            className={`${activeRoute === 'shellbox' ? 'selected' : ''}`}
          >
            <div className="icon">
              <BsBox />
            </div>
            <p>ShellBox</p>
          </ButtonHeader>

        </MenuStyled>
        */}
        <SideActions>


        {showNotificationPop &&
            <NotificationPop ref={refNotification} counterNotifications={counterNotifications}>

              <div className={`inner ${!notifications.length ? "no_notification_height" : ""}`}>
                {notifications != null ?
                  !notifications.length ?
                    <div className="no_notifications">
                      <p>No hay notificaciones pendientes</p>
                    </div>
                  :
                  <ul className={`notification_items ${counterNotifications ? "notification_items_have_new" : "notification_items_not_new"}`}>
                  {
                    notifications.map((notification, index) => {

                        return(
                          <li
                            className={`notification_item ${notification.status !== 3 ? 'active' : 'inactive'} ${("go_to" in notification && (notification.go_to.path === "course" || notification.go_to.path === "module" || notification.go_to.path === "comment")) ? " with_goto" : ""}`}
                            onClick={() => ("go_to" in notification && (notification.go_to.path === "course" || notification.go_to.path === "module" || notification.go_to.path === "comment")) && handleClicGoto(notification.go_to, notification._id, notification.status)}
                          >
                            <div className="notification_title">

                              <span className="notification_icon">
                                {notification.status === 3 ?
                                <BiEnvelopeOpen />
                                :
                                <BiEnvelope />
                                }
                              </span>

                              <p>{notification.title}</p>

                              {"go_to" in notification ?
                                notification.go_to.path === "course" || notification.go_to.path === "module" ?
                                <div className='goto_icon'>
                                  <RxExternalLink />
                                </div>
                                : "" : ""
                              }

                            </div>
                            <div className="notification_description">
                              <p>{notification.message}</p>
                            </div>
                          </li>
                        )

                    })
                  }
                  </ul>
                :
                <div className="loader">
                  <CircularProgress size={30} />
                </div>

                }

                {/* {counterNotifications ?
                <div className="actions">
                  {!loadingViewAll ?
                  <div className="bt_viewed" onClick={() => handleAllViewed()}>

                      <p>Marcar como visto</p>

                  </div>
                  :
                  <div className="loader_viewall">
                    <CircularProgress size={30} />
                  </div>
                  }
                </div>
                : <></>
                } */}


              </div>

            </NotificationPop>
            }







          <ButtonActionRounded
            onClick={() => handleOpenModalContacto()}
            className="button_contact"
            override_css={{background: {color: {normal: "#FFFFFF", hover: "#FFFFFF"}},
                          icon: {color: {normal: "#000000", hover: "#D8010F"}}}}
          >
            <div className="icon notifications">
              <AiOutlineMessage />
            </div>
          </ButtonActionRounded>

          {userData ?
          <div className="userTagWrapper">
            <UserTag className={`usertag ${activeRoute === 'perfil' ? 'selected' : ''}`} onClick={() => goRoute('perfil')}>
              <ImageRound className="image_round" css_styles={{width: 50, height: 50}} file={userData?.media}>
                {userData && userData?.media?.length ?
                  <img src={userData.media} />
                :
                  <img src="/images/noavatar.jpg" />
                }
              </ImageRound>
            </UserTag>
          </div> : ""}

          <div className="notification_block" id="notifications">
            <div className="notification_button" onClick={() => handleNotificationPop()}>
              {counterNotifications ?
              <div className="notification_badge">
                <p>{counterNotifications}</p>
              </div>
              :<></>
              }
              <ButtonActionRounded
                onClick={() => { return null; }}
                className="button_notifications"
                override_css={{background: {color: {normal: "#FFFFFF", hover: "#FFFFFF"}},
                              icon: {color: {normal: "#000000", hover: "#D8010F"}}}}
              >
                <div className="icon notifications">
                  <IoMdNotificationsOutline />
                </div>
              </ButtonActionRounded>
            </div>

          </div>

          <ButtonActionRounded
            onClick={() => handleLogout()}
            className="button_logout"
            override_css={{background: {color: {normal: "#FFFFFF", hover: "#FFFFFF"}},
                          icon: {color: {normal: "#000000", hover: "#D8010F"}}}}
          >
            <div className="icon notifications">
              <BiExit />
            </div>
          </ButtonActionRounded>

          <ButtonActionRounded
            onClick={() => handleShowMenu()}
            className="button_hamburger"
            override_css={{background: {color: {normal: "#FFFFFF", hover: "#FFFFFF"}},
                          icon: {color: {normal: "#000000", hover: "#D8010F"}}}}
          >
            <div className="icon hamburger">
              <IoMenu />
            </div>
          </ButtonActionRounded>


        </SideActions>




    </Wrapper>
  )

}

export default Header;
