import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext, ContentContext } from '../../_context';
import CircularProgress from '@material-ui/core/CircularProgress';
import { breakpoints } from '../../_helpers/breakpoints';
import { incentivoTiendas, inventivo_tiendas_2 } from '../../_helpers/commons';

import axios from 'axios';

const Wrapper = styled.div`
    width: 100%;
    .logos{
        display: flex;
        flex-direction: row;
        .select,
        .box,
        .shop{
            width: 28vw;
            height: 24vw;
            padding-bottom: 3vw;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            background-color: #FFC600;
            border-bottom-left-radius: 5vw;
            border-bottom-right-radius: 5vw;
            margin-right: 3vw;
        }
        .select{
            .logo{
                width: 20vw;
                padding-bottom: 1.8vw;
                img{
                    float: left;
                    width: 100%;
                }
            }
        }
        .box{
            .logo{
                width: 20vw;
                img{
                    float: left;
                    width: 100%;
                }
            }
        }
        .shop{
            margin-right: 0;
            .logo{
                width: 20vw;
                padding-bottom: 2vw;
                img{
                    float: left;
                    width: 100%;
                }
            }
        }
    }
    .loading{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
        svg{
            color: #FFC600;
        }
    }
    >.inner{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        >.title{
            text-align: center;
            text-transform: uppercase;
            font-weight: 800;
            width: 100%;
            font-size: 40px;
            line-height: 45px;
            margin-bottom: 25px;
            margin-top: 35px;
            /* border-bottom: 1px solid #e9e8e8; */
            .brand{
                white-space: nowrap;
                color: #FFFFFF;//FFC600
            }
        }
        .data_station{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 100%;
            .station{
                display: flex;
                flex-direction: column;
                align-items: center;
                .label{
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 400;
                    text-transform: uppercase;
                }
                .value{
                    font-size: 40px;
                    line-height: 40px;
                    font-weight: 600;
                    margin-top: 5px;
                }
            }
            .name{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 30px;
                .label{
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 400;
                    text-transform: uppercase;
                }
                .value{
                    font-size: 40px;
                    line-height: 40px;
                    font-weight: 600;
                    margin-top: 5px;
                }
            }
            .select_boca{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 35px;
                width: 100%;
                label{
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 16px;
                }
                select{
                    width: 100%;
                    padding: 10px 10px;
                }
            }
        }
        .objective_title{
            font-size: 23px;
            line-height: 23px;
            font-weight: 800;
            text-transform: uppercase;
            margin-top: 30px;
            color: #FFFFFF;//FFC600
            border-bottom: 1px solid #FFFFFF;
            text-align: center;
        }
        .copy{
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-top: 30px;
            color: #212529;
            p{
                margin: 0;
                padding: 0;
                font-size: 16px;
                line-height: 20px;
            }
            .l1{
            }
            .l2,
            .l3{
                font-weight: 600;
                margin-top: 10px;
            }
            .l3{
                color: #FFC600;
            }
        }
        .box_objective{
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            width: 100%;
            ul, li{
                margin: 0;
                padding: 0;
                list-style: none;
            }
            ul{
                display: flex;
                flex-direction: column;
                li{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    /* background-color: aliceblue; */
                    background-color: rgba(255,255,255,0.95);
                    padding: 20px 30px;
                    margin-right: 0;
                    margin-top: 20px;
                    border-radius: 6px;
                    border: 1px solid #FFC600;
                    border-bottom: 5px solid #FFC600;

                    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
                    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
                    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
                    :last-child{
                        margin-right: 0;
                    }
                    .label{
                        text-transform: uppercase;
                        font-size: 12px;
                        line-height: 12px;
                        font-weight: 600;
                    }
                    .value{
                        font-size: 40px;
                        line-height: 40px;
                        font-weight: 800;
                        margin-top: 7px;
                        color: #FFC600;
                    }
                }
            }
        }
    }
    @media ${breakpoints.xs} {
        .logos{
            display: flex;
            flex-direction: row;
            .select,
            .box,
            .shop{
                width: 150px;
                height: 130px;
                padding-bottom: 15px;
                border-bottom-left-radius: 25px;
                border-bottom-right-radius: 25px;
                margin-right: 20px;
            }
            .select{
                .logo{
                    width: 100px;
                    padding-bottom: 5px;
                }
            }
            .box{
                .logo{
                    width: 90px;
                    img{
                        float: left;
                        width: 100%;
                    }
                }
            }
            .shop{
                margin-right: 0;
                .logo{
                    width: 100px;
                    padding-bottom: 8px;
                }
            }
        }
        >.inner{
            .box_objective{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-top: 30px;
                ul, li{
                    margin: 0;
                    padding: 0;
                    list-style: none;
                }
                ul{
                    display: flex;
                    flex-direction: row;
                    li{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        /* background-color: aliceblue; */
                        padding: 20px 30px;
                        margin-right: 20px;
                        margin-top: 0;
                        border-radius: 6px;
                        border: 1px solid #FFC600;
                        border-bottom: 5px solid #FFC600;

                        box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
                        -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
                        -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
                        :last-child{
                            margin-right: 0;
                        }
                        .label{
                            text-transform: uppercase;
                            font-size: 12px;
                            line-height: 12px;
                            font-weight: 600;
                        }
                        .value{
                            font-size: 40px;
                            line-height: 40px;
                            font-weight: 800;
                            margin-top: 7px;
                            color: #FFC600;
                        }
                    }
                }
            }
            .data_station{
                .select_boca{
                    margin-top: 35px;
                    width: 400px;
                    label{
                        text-transform: uppercase;
                        font-size: 16px;
                        line-height: 16px;
                    }
                    select{
                        width: 100%;
                        padding: 10px 10px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.sm} {

    }
    @media ${breakpoints.md} {

    }
    @media ${breakpoints.lg} {

    }
`

const IncentivoTiendas = props => {
    
    let history = useHistory();

    const { isAuth } = useContext(AuthContext);
    const { userData } = useContext(ContentContext);

    const [ metaData, setMetaData ] = useState(null);
    const [ dataStation, setDataStation ] = useState(null);
    const { search, pathname } = useLocation();
    const [ uid, setUid ] = useState(null);

    useEffect(() => {

        if(pathname){
            console.log("pathname", pathname)
            if(pathname == '/incentivo-tiendas'){
                if(!isAuth){
                    if(search.length > 0) {
                        
                        const query = new URLSearchParams(search);
                        if(query.get("uid") && query.get("uid").length > 0){
                            setUid(query.get("uid"));
                        }else{

                        }
                    }else{

                    }
                }
            }
        }
        return () => {}

    }, [pathname, isAuth])

    useEffect(() => {

        if(isAuth && userData){
            setUid(userData.dni);
        }
        return () => {}

    }, [isAuth, userData])

    const getData = async () => {
        //const requestURL = `http://localhost:8888/protagonitas_landings/incentivo_tiendas_2.php`;
        const requestURL = `https://fabianhr.com/protagonistas/incentivo_tiendas_2.php`;

        try {
            const params = new URLSearchParams()
            //params.append('type', "get_data");
            params.append('uid', uid);

            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }

            const result = await axios.post(requestURL, params, config);
            setMetaData(result.data.metas);
            setDataStation(result.data.metas[0]);

            return result;

        } catch (error) {

        }
    }

    const handleChangeBoca = (e) => {
        const data = metaData.filter(el => el.boca === e.target.value);
        setDataStation(data[0]);
    }

    useEffect(() => {
        
        if(uid){
            getData();
        }
    
      return () => {}
    }, [uid])
    
    useEffect(() => {

        if(userData){
            if(userData.dni){
                
                if(!inventivo_tiendas_2.includes(userData?.dni)){
                    history.push('/');
                }
            }
        }
      
        return () => {}
      }, [userData])
    

    return (
        <Wrapper>
            {(!metaData && !userData && !dataStation) ? 

                <div className='loading'>
                    <CircularProgress />
                </div>

                :
            
                <div className='inner'>
                    <div className='logos'>
                        <div className='select'>
                            <div className='logo'>
                                <img src='images/logo_shell_select.svg' />
                            </div>
                        </div>
                        <div className='box'>
                            <div className='logo'>
                                <img src='images/logo_shellbox_incentivo_tiendas.png' />
                            </div>
                        </div>
                        <div className='shop'>
                            <div className='logo'>
                                <img src='images/Shell-SHOP.jpg' />
                            </div>
                        </div>
                    </div>
                    <div className='title'>
                        META <span className='brand'>Shell BOX</span><br/>EN TIENDAS 
                    </div>
                    <div className='data_station'>
                        <div className='station'>
                            <div className='label'>Boca</div> 
                            <div className='value'>{dataStation?.boca}</div>
                        </div>
                        {/* <div className='name'>
                            <div className='label'>
                                Razon Social
                            </div>
                            <div className='value'>{dataStation?.razon}</div>
                        </div> */}
                        {metaData?.length > 1 ?
                        <div className='select_boca'>
                            <label>Seleccionar boca</label>
                            <select id="" onChange={handleChangeBoca}>
                                {metaData.map((boca, index) => {
                                    return(
                                        <option value={boca.boca}>{`${boca.boca} - ${boca.razon}`}</option>
                                    )
                                })}
                            </select>
                        </div>
                        : ""}
                    </div>
                    <div className='objective_title'>
                        Resultados
                    </div>
                    <div className='box_objective'>
                        <ul>
                            <li>
                                <div className='label'>
                                    Promedio Mensual TXS
                                </div>
                                <div className='value'>
                                    {dataStation?.promedio ? dataStation.promedio : '---'}
                                </div>
                            </li>
                            <li>
                                <div className='label'>
                                    Resultado
                                </div>
                                <div className='value'>
                                    {dataStation?.resultado ? dataStation.resultado : '---'}
                                </div>
                            </li>
                            <li>
                                <div className='label'>
                                    % de Cumplimiento
                                </div>
                                <div className='value'>
                                    {dataStation?.incremento ? dataStation.incremento : '---'}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className='copy'>
                        <p className='l1'>Las estaciones compiten según ligas: Liga A a partir de 150 txs / Liga B  desde 50 a 149 txs / Liga C desde 30 a 49 txs</p>
                        <p className='l2'>Vigencia desde el 10 al 31 de Julio.</p>
                        <p className='l2'>Los resultados se actualizarán cada 24 a 48hs hábiles.</p>
                        {/* <p className='l3'>Fecha actualización 03/04/2024</p> */}
                    </div>
                </div>
            }
        </Wrapper>
    );
};

export default IncentivoTiendas;